import { IndexPage, defaultLayoutComponets } from '@/views/layout'
const routes = [
  {
    path: 'PD',
    component: IndexPage,
    children: [
      {
        path: 'PD0101M01',
        name: 'PD0101M01',
        components: Object.assign({ content: () => import(/* webpackChunkName: "play_PD" */ '@/views/PD/PD0101M01.vue') }, defaultLayoutComponets),
        meta: { requiresAuth: false, title: '랭킹', description: '포인트 랭킹' }
      },
      {
        path: 'PD0101M02',
        name: 'PD0101M02',
        components: Object.assign({ content: () => import(/* webpackChunkName: "play_PD" */ '@/views/PD/PD0101M02.vue') }, defaultLayoutComponets),
        meta: { requiresAuth: false, title: '탑 랭커 로드', description: '탑 랭커 로드' }
      },
      {
        path: 'PD0102M01',
        name: 'PD0102M01',
        components: Object.assign({ content: () => import(/* webpackChunkName: "play_PD" */ '@/views/PD/PD0102M01.vue') }, defaultLayoutComponets),
        meta: { requiresAuth: false, title: '랭킹', description: '추천인 랭킹' }
      },
      {
        path: 'PD0103M01',
        name: 'PD0103M01',
        components: Object.assign({ content: () => import(/* webpackChunkName: "play_PD" */ '@/views/PD/PD0103M01.vue') }, defaultLayoutComponets),
        meta: { requiresAuth: false, title: '랭킹', description: '핀플 랭킹' }
      }
    ]
  }
]
export default routes
