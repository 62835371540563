<template>
  <v-card class="comment-item">
    <div class="user-wrap" @click="moveActivityLog">
      <UiUserImg :data="commentObj" :img-class="'user-img'" :img-type="'finfle'"></UiUserImg>

      <div class="user-info">
        <div class="user-name">
          {{ commentObj.nickNm }}
        </div>
        <template v-for="(cate, idx) in category" :key="idx">
          <v-chip size="small" :color="getColor(cate)">{{ getCategoryNm(cate) }}</v-chip>
        </template>
        <span class="user-time">
          {{ commentObj.regDtMark }}
        </span>
      </div>
    </div>
    <div class="docu-wrap">
      <div v-dompurify-html="commentObj.contents" class="docu"></div>
    </div>

    <div class="action-wrap d-flex">
      <v-checkbox
        v-model="likeCheckYn"
        class="like"
        :label="numberFormat(commentObj.likeCount, 0)"
        hide-details="auto"
        @change="modifyLike('LIKE')"
      ></v-checkbox>
      <v-checkbox
        v-if="dislikeYn"
        v-model="hateCheckYn"
        class="dislike"
        :label="numberFormat(commentObj.hateCount, 0)"
        hide-details="auto"
        @change="modifyLike('HATE')"
      ></v-checkbox>

      <v-btn v-if="commentObj.parCommentNo == 0" variant="text" size="small" class="text-light-4" @click="clickReComment"> 댓글달기 </v-btn>
      <v-btn class="ml-auto" icon @click="openPostEdit = true">
        <v-icon class="more">버튼 더보기</v-icon>
      </v-btn>
    </div>
  </v-card>

  <UiBottom :is-open-props="openPostEdit" @btm-close="openPostEdit = false">
    <UiSelectList :items="commentEditList" class="mt-n3" @selected-item="selectedItem" />
  </UiBottom>
  <UiBottom title="이 글에 어떤 문제가 있나요?" :is-open-props="openNotify" @btm-close="openNotify = false">
    <div>24시간 이내에 검토되며 신고가 확정되면</div>
    <div>글은 삭제되고 유저는 글작성이 차단됩니다.</div>
    <div class="checkbox-list">
      <ul>
        <li v-for="notify in notifyList" :key="notify.value">
          <v-checkbox v-model="selectedReport" :label="notify.text" hide-details="auto" :value="notify.value"></v-checkbox>
        </li>
      </ul>
    </div>
    <template #footer>
      <div class="btn-wrap">
        <v-btn color="primary" @click="confirmReport">확인</v-btn>
      </div>
    </template>
  </UiBottom>
  <UiSnackbar :is-open-props="isSnackbarOpen" :description="snackbarText" :open-type="snackbarType" @snack-close="closeSnackbar"></UiSnackbar>
</template>
<script>
import {
  // SelectOption,
  // CommentWrite,
  UiUserImg,
  UiBottom,
  UiSnackbar
} from '$$bizcomponents'
// import CommentWrite from '/src/components/pub/CommentWrite.vue'
import Common from '$$utils/common'
import UiSelectList from './UiSelectList.vue'

import { isUndefined, find, filter, map, isEmpty } from 'lodash' // , unescape

import { reactive } from 'vue'
import { useAuthStore } from '@/store/index'
export default {
  name: 'UiCommentItem',
  components: {
    // CommentWrite,
    UiUserImg,
    UiSelectList,
    UiBottom,
    UiSnackbar
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      }
    }
  }, //'CommentItem',
  emits: ['re-comment', 'remove-comment'],
  setup() {
    // 공통코드 조회
    const pinia = useAuthStore()
    const categoryList = reactive(pinia.getCommonCode('ST0044')) // 사용자 카드 색상 코드

    let editList = pinia.getCommonCode('ST0050') // 댓글 처리 목록 코드
    map(editList, obj => {
      obj.icon = obj.value
    })
    const commentEditList = reactive(editList) // 댓글 처리 목록 코드
    const notifyList = pinia.getCommonCode('ST0039') // 신고 사유 목록 코드
    return {
      categoryList,
      commentEditList,
      notifyList
    }
  },
  data() {
    return {
      commentObj: {},
      dislikeYn: false,
      likeCheckYn: false,
      hateCheckYn: false,

      category: [],

      openPostEdit: false,
      openNotify: false,
      selectedReport: '',

      // 스낵바
      isSnackbarOpen: false,
      snackbarText: '',
      snackbarType: ''
    }
  },
  computed: {
    isAuth() {
      return this.$pinia.auth.isAuth
    },
    userId() {
      return this.$pinia.auth.userData.userId
    }
  },
  mounted() {
    this.commentObj = this.item
    // this.commentObj.contents = unescape(this.commentObj.contents)
    this.setCommentData()
    // console.log('commentObj!!!', this.commentObj)
  },
  methods: {
    // 댓글 데이터 셋팅
    setCommentData() {
      const val = this.commentObj

      // 사용자 카드 노출 추가
      if (val.registerDefaultLevel === 3) {
        const userCard = find(this.categoryList, { value: 'FIN' })
        this.category.push(userCard.value)
      }

      this.dislikeYn = val.tableId !== 'FINFLUENCER'
      // 좋아요/싫어요
      this.likeCheckYn = val.likeYn === 'Y'
      this.hateCheckYn = val.hateYn === 'Y'

      // 댓글 edit 옵션
      let findType = 'none'
      if (val.regId === this.userId) {
        findType = 'own'
      }
      this.commentEditList = filter(this.commentEditList, obj => {
        if (obj.note === findType) return obj
      })
    },
    // 주제카드 및 사용자카드 셋팅
    getColor(id) {
      const findObj = find(this.categoryList, { value: id })
      return isEmpty(findObj) ? '' : findObj.note
    },
    getCategoryNm(id) {
      const findObj = find(this.categoryList, { value: id })
      return isEmpty(findObj) ? '' : findObj.text
    },
    // 대댓글 등록 클릭
    clickReComment() {
      const result = {
        result: 'success',
        value: this.commentObj.commentNo
      }
      this.$emit('re-comment', result)
    },
    numberFormat(value, dotNum) {
      if (isUndefined(value)) return ''
      else {
        let dotFNum = isUndefined(dotNum) ? 0 : dotNum
        return Common.numberFormat(value, dotFNum)
      }
    },
    // 좋아요/싫어요 처리
    modifyLike(type) {
      // console.log('modifyLike!!! init', type)
      const typeLower = type.toLowerCase()
      if (this.isAuth) {
        // 좋아요나 싫어요 해제 시 useYn = 'N' 처리
        const isUseYn = this.commentObj[typeLower + 'Yn'] === 'Y' ? 'N' : 'Y'
        const params = {
          list: [
            {
              cmd: 'I',
              tableId: this.commentObj.tableId,
              // boardType: this.commentObj.boardType,
              contentsNo: this.commentObj.contentsNo,
              commentNo: this.commentObj.commentNo,
              userId: this.$pinia.auth.userData.userId,
              actionTypeCd: type, // 활동구분코드 LIKE HATE
              useYn: isUseYn,
              writeTypeCd: 'COMMENT'
            }
          ]
        }
        const modiLikeApi = '/GW/router/STOPLAY/CMN/CmnMemberPostAction/save'
        this.$http.post(modiLikeApi, params).then(res => {
          const result = res.data
          if (result.result.code === 'SUCCESS') {
            if (this.commentObj[typeLower + 'Yn'] === 'Y') {
              this.commentObj[typeLower + 'Yn'] = 'N'
              this.commentObj[typeLower + 'Count']--
            } else {
              this.commentObj[typeLower + 'Yn'] = 'Y'
              this.commentObj[typeLower + 'Count']++

              // 좋아요/싫어요 하나만 가능하도록 처리
              if (type === 'LIKE' && this.commentObj.hateYn == 'Y') {
                this.commentObj.hateYn = 'N'
                this.commentObj.hateCount--
                this.hateCheckYn = false
              } else if (type === 'HATE' && this.commentObj.likeYn == 'Y') {
                this.commentObj.likeYn = 'N'
                this.commentObj.likeCount--
                this.likeCheckYn = false
              }
            }
          } else {
            this[typeLower + 'CheckYn'] = !this[typeLower + 'CheckYn']
            this.$alert('알림', result.result.clientMessage)
          }
        })
      } else {
        this[typeLower + 'CheckYn'] = false
        this.openLoginModal()
      }
    },
    openLoginModal() {
      this.$confirm('알림', this.$t('message.STC_0004'), '취소', '로그인/회원가입', undefined).then(result => {
        if (result == 'secondResult') {
          this.moveLogin()
        }
      })
    },
    moveLogin() {
      this.$router.push({ name: 'CM0100M01' })
    },
    selectedItem(item) {
      this.openPostEdit = false
      if (item.value == 'delete') {
        console.log('delete')
        this.$confirm('알림', '<h3>내 의견을 삭제할까요?</h3>', '취소', '삭제하기', undefined, '', 'out').then(res => {
          console.log('삭제처리 !!!!', res)
          if (res === 'secondResult') {
            this.deleteComment()
          }
        })
      } else if (item.value == 'notify') {
        if (this.isAuth) this.openNotify = true
        else this.openLoginModal()
      }
    },
    confirmReport() {
      console.log('신고하기!!!', this.selectedReport)
      const reportObj = find(this.notifyList, { value: this.selectedReport })

      const reportApi = '/GW/router/STOPLAY/BRD/BrdReport/save'
      const _params = {
        list: [
          {
            cmd: 'I',
            tableId: this.commentObj.tableId,
            // boardType: this.commentObj.boardType,
            contentsNo: this.commentObj.contentsNo,
            commentNo: this.commentObj.commentNo,
            reportTypeCd: 'COMMENT', // 신고유형 POST	게시글 / COMMENT	코멘트 / USER	사용자
            reportRsnCd: reportObj.value, // 신고사유
            reportRsnContents: reportObj.text, // 신고사유내용
            reportStatusCd: '01', // 신고상태
            reporterId: this.userId // 신고자
          }
        ]
      }
      this.$http.post(reportApi, _params).then(async res => {
        const result = res.data
        if (result.result.code === 'SUCCESS') {
          this.openNotify = false
          this.selectedReport = ''
          this.isSnackbarOpen = true
          this.snackbarType = 'report'
          this.snackbarText = this.$t('message.STT_0047')
        } else {
          this.$alert('알림', result.result.clientMessage)
        }
      })
    },
    deleteComment() {
      const boardApi = '/GW/router/STOPLAY/BRD/BrdPostComment/save'
      const _params = {
        list: [
          {
            cmd: 'D',
            tableId: this.commentObj.tableId,
            contentsNo: this.commentObj.contentsNo,
            commentNo: this.commentObj.commentNo
          }
        ]
      }
      this.$http.post(boardApi, _params).then(async res => {
        const result = res.data
        if (result.result.code === 'SUCCESS') {
          console.log('이동하기!!!')
          // 댓글에서 삭제시키기
          this.$emit('remove-comment', this.commentObj)
        } else {
          this.$alert('알림', result.result.clientMessage)
        }
      })
    },
    closeSnackbar(snackbarType) {
      // 스낵바 종료
      this.isSnackbarOpen = false
      if (snackbarType === 'success') {
        // this.$modalHide(this.modalName, { result: true })
      }
    },
    moveActivityLog() {
      // 활동로그로 이동
      this.$router.push({ path: '/CO/CO0104M00', state: { userId: this.commentObj.regId } })
    }
  }
}
</script>
