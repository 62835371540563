<template>
  <!-- 1:1 문의 문의하기 -->
  <div class="modal-popup">
    <div class="modal-container has-float-bottom">
      <div class="borderWhite wrap-question">
        <div class="wrap-scroll">
          <div ref="form" class="wrap-form">
            <div class="input-area">
              <!-- getAppVersion -->
              <p class="tit certain">getAppVersion</p>
              <v-text-field v-model="appVersion" variant="outlined" class="tit-box" clearable maxlength="1000"></v-text-field>
              <v-btn color="white" size="small" @click="getAppVersion()">실행</v-btn>
              <br /><br />
              <v-divider :thickness="2"></v-divider>
              <br />
              <!-- getDeviceId -->
              <p class="tit certain">getDeviceId</p>
              <v-text-field v-model="deviceId" variant="outlined" class="tit-box" maxlength="1000" counter></v-text-field>
              <v-btn color="white" size="small" @click="getDeviceId()">실행</v-btn>
              <br /><br />
              <v-divider :thickness="2"></v-divider>
              <br />
              <!-- getDeviceModel -->
              <p class="tit certain">getDeviceModel</p>
              <v-text-field v-model="deviceModel" variant="outlined" class="tit-box" maxlength="1000" counter></v-text-field>
              <v-btn color="white" size="small" @click="getDeviceModel()">실행</v-btn>
              <br /><br />
              <v-divider :thickness="2"></v-divider>
              <br />
              <!-- getDeviceOS -->
              <p class="tit certain">getDeviceOS</p>
              <v-text-field v-model="deviceOS" variant="outlined" class="tit-box" maxlength="1000" counter></v-text-field>
              <v-btn color="white" size="small" @click="getDeviceOS()">실행</v-btn>
              <br /><br />
              <v-divider :thickness="2"></v-divider>
              <br />
              <!-- setCameraPermission -->
              <p class="tit certain">setCameraPermission</p>
              <v-text-field v-model="cameraPermission" variant="outlined" class="tit-box" maxlength="1000" counter></v-text-field>
              <v-btn color="white" size="small" @click="setCameraPermission('ON')">ON실행</v-btn>
              <v-btn color="white" size="small" @click="setCameraPermission('OFF')">OFF실행</v-btn>
              <br /><br />
              <v-divider :thickness="2"></v-divider>
              <br />
              <!-- setPushNotice -->
              <p class="tit certain">setPushNotice</p>
              <v-text-field v-model="pushNotice" variant="outlined" class="tit-box" maxlength="1000" counter></v-text-field>
              <v-btn color="white" size="small" @click="setPushNotice('ON')">ON실행</v-btn>
              <v-btn color="white" size="small" @click="setPushNotice('OFF')">OFF실행</v-btn>
              <br /><br />
              <v-divider :thickness="2"></v-divider>
              <br />
              <!-- callDial -->
              <p class="tit certain">callDial</p>
              <v-text-field v-model="callDialValue" variant="outlined" class="tit-box" maxlength="1000" counter></v-text-field>
              <v-btn color="white" size="small" @click="callDial()">실행</v-btn>
              <br /><br />
              <v-divider :thickness="2"></v-divider>
              <br />
              <!-- showCamera -->
              <p class="tit certain">showCamera</p>
              <v-text-field v-model="showCameraValue" variant="outlined" class="tit-box" maxlength="1000" counter></v-text-field>
              <v-btn color="white" size="small" @click="showCamera()">실행</v-btn>
              <br /><br />
              <v-divider :thickness="2"></v-divider>
              <br />
              <!-- showGallery -->
              <p class="tit certain">showGallery</p>
              <v-text-field v-model="showGalleryValue" variant="outlined" class="tit-box" maxlength="1000" counter></v-text-field>
              <v-btn color="white" size="small" @click="showGallery()">실행</v-btn>
              <br /><br />
              <v-divider :thickness="2"></v-divider>
              <br />
              <!-- showShareList -->
              <p class="tit certain">showShareList</p>
              <v-text-field v-model="showShareListValue" variant="outlined" class="tit-box" maxlength="1000" counter></v-text-field>
              <v-btn color="white" size="small" @click="showShareList('공유하기', '공유하기내용입니다', 'https://www.dobu.kr')">실행</v-btn>
              <br /><br />
              <v-divider :thickness="2"></v-divider>
              <br />
              <!-- openWindow -->
              <p class="tit certain">openWindow</p>
              <v-text-field v-model="openWindowValue" variant="outlined" class="tit-box" maxlength="1000" counter></v-text-field>
              <v-btn color="white" size="small" @click="openWindow('https://www.naver.com')">실행</v-btn>
              <br /><br />
              <v-divider :thickness="2"></v-divider>
              <br />
              <!-- userAgent -->
              <p class="tit certain">userAgent</p>
              <v-text-field v-model="userAgentValue" variant="outlined" class="tit-box" maxlength="1000" counter></v-text-field>
              <v-btn color="white" size="small" @click="showUserAgent()">실행</v-btn>
              <br /><br />
              <v-divider :thickness="2"></v-divider>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CS9901L99',

  components: {},
  props: {
    modalName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      appVersion: '',
      deviceId: '',
      deviceModel: '',
      deviceOS: '',
      cameraPermission: '',
      pushNotice: '',
      callDialValue: '',
      showCameraValue: '',
      showGalleryValue: '',
      showShareListValue: '',
      openWindowValue: '',
      userAgentValue: ''
    }
  },

  computed: {},

  methods: {
    // 앱의 버전을 불러온다
    getAppVersion() {
      this.$nativeApi.getAppVersion().then(res => {
        console.log('getAppVersion', res)
        this.appVersion = JSON.stringify(res)
      })
    },
    // 앱의 장비ID를 불러온다
    getDeviceId() {
      this.$nativeApi.getDeviceId().then(res => {
        console.log('getDeviceId', res)
        this.deviceId = JSON.stringify(res)
      })
    },
    // 기기 모델정보를 불러온다
    getDeviceModel() {
      this.$nativeApi.getDeviceModel().then(res => {
        console.log('getDeviceModel', res)
        this.deviceModel = JSON.stringify(res)
      })
    },
    // 기기 OS 정보를 불러온다
    getDeviceOS() {
      this.$nativeApi.getDeviceOS().then(res => {
        console.log('getDeviceOS', res)
        this.deviceOS = JSON.stringify(res)
      })
    },
    // setValue - 'ON' OR 'OFF'
    // 카메라/사진 접근 권한 설정
    setCameraPermission(setValue) {
      console.log('setValue', setValue)
      this.$nativeApi.setCameraPermission(setValue).then(res => {
        console.log('setCameraPermission', res)
        this.cameraPermission = JSON.stringify(res)
      })
    },
    // setValue - 'ON' OR 'OFF'
    // Push 알림 수신 설정
    setPushNotice(setValue) {
      console.log('setValue', setValue)
      this.$nativeApi.setPushNotice(setValue).then(res => {
        console.log('setPushNotice', res)
        this.pushNotice = JSON.stringify(res)
      })
    },
    // 전화걸기
    callDial: function (phoneNo) {
      console.log('phoneNo', phoneNo)
      this.$nativeApi.callDial(phoneNo).then(res => {
        console.log('setPushNotice', res)
        this.callDialValue = JSON.stringify(res)
      })
    },
    // 카메라 열기
    showCamera() {
      this.$nativeApi.showCamera().then(res => {
        console.log('showCamera', res)
        this.showCameraValue = JSON.stringify(res)
      })
    },
    // 겔러리 열기
    showGallery() {
      this.$nativeApi.showGallery().then(res => {
        console.log('showGallery', res)
        this.showGalleryValue = JSON.stringify(res)
      })
    },
    // 공유하기 목록을 보여준다
    showShareList(title, text, url) {
      console.log('title, text, url', title, text, url)
      this.$nativeApi.showShareList(title, text, url).then(res => {
        console.log('showShareList', res)
        this.showShareListValue = JSON.stringify(res)
      })
    },
    // 앱외부 브라우저로 오픈
    openWindow(url) {
      console.log('url', url)
      this.$nativeApi.openWindow(url).then(res => console.log('openWindow', res))
    },
    showUserAgent() {
      this.userAgentValue = window.navigator.userAgent
    }
  }
}
</script>

<style lang="scss"></style>
