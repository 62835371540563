<template>
  <div class="side-content">
    <UiSwiperMainEvent></UiSwiperMainEvent>
    <h2 class="text-h3 mt-10">베스트 커뮤니티</h2>
    <v-tabs v-model="tabType" class="type02 mt-4 ml-n6">
      <v-tab v-for="item in typeList" :key="item.value" :value="item.value">
        {{ item.text }}
      </v-tab>
    </v-tabs>
    <ul class="feed-item-list mt-4">
      <li v-for="item in feedItems" :key="item.id">
        <UiFeedCard :item="item" />
      </li>
    </ul>
  </div>
</template>
<script>
import { UiSwiperMainEvent, UiFeedCard } from '$$bizcomponents'
import { useAuthStore } from '@/store/index'
import { isEmpty, find } from 'lodash'

export default {
  name: 'SideContent',
  components: {
    UiSwiperMainEvent,
    UiFeedCard
  },
  setup() {
    // 공통코드 조회
    const pinia = useAuthStore()
    const typeList = pinia.getCommonCode('ST0054') // 핀플채널/오픈톡 공통코드
    return {
      typeList
    }
  },
  data() {
    return {
      tabType: 'FIN',
      searchTypeList: [
        { id: 'OPEN', boardType: 'BRD', tableId: 'FREE' },
        { id: 'FIN', boardType: 'FIN', tableId: 'FINFLUENCER', baseId: 'ALL' }
      ],
      feedItems: [],
      currentPage: 5,
      searchType: 'WLIKE'
    }
  },
  computed: {
    isReloadSide() {
      return this.$pinia.com.isReloadSide
    }
  },
  watch: {
    async tabType(nVal) {
      const findObj = find(this.searchTypeList, { id: nVal })
      const searchParam = { ...findObj, cntPerPage: this.currentPage, searchType: this.searchType }
      await this.getFeedList(searchParam, 'feedItems')
    },
    async isReloadSide(nVal) {
      if (nVal) {
        const findObj = find(this.searchTypeList, { id: this.tabType })
        const searchParam = { ...findObj, cntPerPage: this.currentPage, searchType: this.searchType }
        await this.getFeedList(searchParam, 'feedItems')
      }
    }
  },
  async mounted() {
    const findObj = find(this.searchTypeList, { id: this.tabType })
    const searchParam = { ...findObj, cntPerPage: this.currentPage, searchType: this.searchType }
    await this.getFeedList(searchParam, 'feedItems')
  },
  methods: {
    async getFeedList(srchParam, target) {
      // 게시글 초기화
      this[target] = []
      // 게시글 조회
      const feedApi = '/GW/router/STOPLAY/BRD/BrdPost/list'
      // const srchParam = this.searchParam[type]
      const params = { currentPage: 1, ...srchParam }

      await this.$http.post(feedApi, params).then(res => {
        const result = res.data
        if (result.result.code === 'SUCCESS') {
          this.isLoad = true
          if (!isEmpty(result.data)) {
            console.log('[게시글 정보 조회!!!]', result.data)
            this[target] = result.data
          } else {
            // 데이터 미존재 시
            // this.feedItems = []
            this[target] = []
          }
        } else {
          this.$alert('알림', result.result.clientMessage)
        }
      })
      this.$pinia.com.isReloadSide = false
    }
  }
}
</script>
