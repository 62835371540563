import { defineStore } from 'pinia'
import authStore from './pinia/AuthStore'
import loadingStore from './pinia/LoadingStore'
import menuStore from './pinia/MenuStore'
import routeStore from './pinia/RouteStore'
import popupStore from './pinia/popupStore'
import ComStore from './pinia/ComStore'
import DomStore from './pinia/DomStore'

const useAuthStore = defineStore('auth', authStore)
const useLoadingStore = defineStore('loading', loadingStore)
const useMenuStore = defineStore('menu', menuStore)
const useRouteStore = defineStore('route', routeStore)
const usePopupStore = defineStore('popup', popupStore)
const useComStore = defineStore('com', ComStore)
const useDomStore = defineStore('dom', DomStore)

export { useAuthStore, useLoadingStore, useMenuStore, useRouteStore, usePopupStore, useComStore, useDomStore }
