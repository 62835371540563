<template>
  <swiper
    class="swiper-feed"
    :direction="isVertical"
    :autoplay="isAutoplay"
    :modules="modules"
    :pagination="pagination"
    :space-between="10"
    @init="initSwiper"
    @swiper="onSwiper"
    @slide-change="onSlideChange"
  >
    <swiper-slide v-for="feed in swiperItem" :key="feed.contentsNo">
      <UiFeedCard :item="feed" :class="color" />
    </swiper-slide>
  </swiper>
</template>
<script>
import 'swiper/css/pagination'
import 'swiper/css'
import { Pagination, Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { UiFeedCard } from '$$bizcomponents'
import { isUndefined, isEmpty, forEach, find, reduce } from 'lodash'
export default {
  name: 'UiFeedSwiper', // 'SwiperFeed',
  components: {
    Swiper,
    SwiperSlide,
    UiFeedCard
  },
  props: {
    items: {
      type: Array,
      default: () => {
        return []
      },
      required: true
    },
    color: {
      type: String,
      default: ''
    },
    vertical: {
      type: Boolean,
      default: false
    },
    isGetFeed: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const onSwiper = swiper => {
      console.log(swiper)
    }
    const onSlideChange = () => {
      console.log('slide change')
    }
    return {
      onSwiper,
      onSlideChange,
      modules: [Pagination, Autoplay]
    }
  },
  data() {
    return {
      swiperItem: [],
      swiperInstance: undefined,
      pagination: false,
      isVertical: this.vertical ? 'vertical' : 'horizontal',
      isAutoplay: this.vertical
        ? {
            delay: 2000,
            disableOnInteraction: false
          }
        : false,
      defaultObj: {
        likeYn: 'N',
        likeCount: 0,
        hateYn: 'N',
        hateCount: 0,
        replyCount: 0
      }
    }
  },
  watch: {
    items: {
      handler: async function (nVal) {
        if (this.isGetFeed && !isEmpty(nVal)) {
          this.swiperItem = []
          const contentsNoList = []
          await forEach(nVal, val => {
            contentsNoList.push(val.contentsNo)
          })
          this.getFeedData(contentsNoList)
        } else {
          this.swiperItem = nVal
        }
        if (!isUndefined(this.swiperInstance)) {
          this.swiperInstance.update()
          this.swiperInstance.slideTo(0)
        }
      },
      deep: true
    },
    hidePagination: {
      handler(val) {
        this.pagination = val ? false : { clickable: true }
      },
      immediate: true
    }
  },
  async mounted() {
    if (this.isGetFeed && !isEmpty(this.items)) {
      this.swiperItem = []
      const contentsNoList = []
      await forEach(this.items, val => {
        contentsNoList.push(val.contentsNo)
      })
      this.getFeedData(contentsNoList)
    } else {
      this.swiperItem = this.items
    }
    if (this.hidePagination) {
      this.pagination = false
    }
  },
  methods: {
    initSwiper(instance) {
      this.swiperInstance = instance
    },
    getFeedData(contentsNoList) {
      if (contentsNoList.length > 0) {
        // 게시글 내용 상세 조회
        const feedApi = '/GW/router/STOPLAY/BRD/BrdPost/list'
        const _params = {
          tableId: this.items[0].tableId,
          boardType: this.items[0].boardType,
          useYn: 'Y',
          contentsNoList: contentsNoList,
          currentPage: 1,
          cntPerPage: contentsNoList.length
        }
        _params.baseId = this.items[0].tableId === 'FINFLUENCER' ? 'ALL' : ''

        this.$http.post(feedApi, _params, { loadingOff: true }).then(async res => {
          const result = res.data
          if (result.result.code === 'SUCCESS') {
            if (!isEmpty(result.data)) {
              const feedList = result.data
              // 순번 맞춰주기
              this.swiperItem = reduce(
                contentsNoList,
                (crr, arr) => {
                  const feedObj = find(feedList, { contentsNo: arr })
                  if (!isEmpty(feedObj)) {
                    crr.push(feedObj)
                  }
                  return crr
                },
                []
              )
            } else {
              // 데이터 미존재 시
              this.swiperItem = []
            }
          } else {
            this.$alert('알림', result.result.clientMessage)
          }
        })
      }
    }
  }
}
</script>
