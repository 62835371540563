<template>
  <div
    v-if="eventList.length > 0"
    class="main-event-wrap"
    :style="'padding-top:' + (eventList.length - 1) * topHeight + 'px'"
    :class="{ open: openCard }"
  >
    <v-btn size="x-small" @click="clickOpen">
      <v-icon :class="[openCard ? 'unexpand' : 'expand']"></v-icon>
      {{ openCard ? '접기' : '펼쳐보기' }}
    </v-btn>
    <div
      v-for="(item, index) in eventList"
      :key="index"
      class="event-item cursor-pointer"
      :class="[item.link2, item.active]"
      :style="'top:' + topHeight * index * -1 + 'px;' + 'z-index:' + (eventList.length - index)"
      @click="clickItem(index)"
      @animationend="removeAction(item)"
    >
      <p class="title">
        {{ item.title }}
      </p>
      <p v-dompurify-html="item.contents" class="docu"></p>
      <div v-if="item.postingYn === 'Y'" class="notice-box">
        <ul class="notice">
          <li>※ 투자 전 설명 청취 및 상품설명서/약관 필독</li>
          <li>※ 원금손실 발생 가능 및 투자자 귀속</li>
        </ul>
        <v-icon class="logo-hana" aria-label="하나증권"></v-icon>
      </div>
      <v-img :src="item.imgUrl"></v-img>
    </div>
  </div>
</template>
<script>
import { map, find } from 'lodash'
export default {
  name: 'UiMainEvent', // 'MainEvent',
  data() {
    return {
      topHeight: 60,
      eventList: [],
      timer: null,
      openCard: false,
      today: '99991231235959'
    }
  },
  mounted() {
    // this.today = await this.getToday()
    this.getBannerList()
  },
  methods: {
    /*
    async getToday() {
      const getTodayApi = '/GW/stoplay/login/getnovasysdate'
      let today = ''
      await this.$http.get(getTodayApi, {}).then(result => {
        const res = result.data
        if (res.result.code === 'SUCCESS') {
          today = res.data
        }
      })
      return today
    },
    */
    clickOpen() {
      this.openCard = !this.openCard
    },
    clickItem(index) {
      if (!this.openCard) {
        this.eventList.forEach(item => {
          item.active = ''
        })
        const item = this.eventList[index]
        clearTimeout(this.timer)
        if (index > 0) {
          item.active = 'down'
          this.timer = setTimeout(() => {
            this.eventList.splice(index, 1)
            this.eventList.unshift(item)
            item.active = 'up'
          }, 200)
        } else {
          this.timer = setTimeout(() => {
            item.active = 'click'
            this.$router.push({ path: this.eventList[index].link1 })
          }, 100)
        }
      } else {
        this.$router.push({ path: this.eventList[index].link1 })
      }
    },
    removeAction(item) {
      if (item.active) {
        item.active = ''
      }
    },
    getBannerList() {
      const getBannerApi = '/GW/router/STOPLAY/BRD/BrdPost/list'
      const param = {
        boardType: 'MAIN',
        tableId: 'BANNER',
        currentPage: 1,
        cntPerPage: 10,
        useYn: 'Y',
        displayYn: 'Y'
      }
      this.$http.post(getBannerApi, param).then(result => {
        const res = result.data
        console.log('[getBannerList !!!]', res)
        if (res.result.code === 'SUCCESS') {
          this.eventList = res.data
          /*
          this.eventList = filter(res.data, obj => {
            return obj.startDt <= this.today && obj.endDt >= this.today
          })
          */
          map(this.eventList, obj => {
            obj.active = ''
            const img = find(obj.fileUploadClientList, { filePathSeqno: 1 })
            obj.imgUrl = '/UPLOAD' + img.fileUrl
          })
        } else {
          this.eventList = []
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.event-item.nth-4 {
  background-color: #057866 !important;
}
</style>
