<template>
  <swiper
    v-if="eventList.length > 0"
    class="main-event-wrap"
    :modules="modules"
    :space-between="10"
    :autoplay="{
      delay: 2500,
      disableOnInteraction: false
    }"
    :pagination="pagination"
    @swiper="onSwiper"
    @slide-change="onSlideChange"
  >
    <swiper-slide v-for="(item, idx) in eventList" :key="item.id">
      <div class="event-item" :class="[item.link2, item.active]" @click="clickItem(idx)">
        <p class="title">
          {{ item.title }}
        </p>
        <p v-dompurify-html="item.contents" class="docu"></p>
        <div v-if="item.postingYn === 'Y'" class="notice-box">
          <ul class="notice">
            <li>※ 투자 전 설명 청취 및 상품설명서/약관 필독</li>
            <li>※ 원금손실 발생 가능 및 투자자 귀속</li>
          </ul>
          <v-icon class="logo-hana" aria-label="하나증권"></v-icon>
        </div>
        <v-img :src="item.imgUrl"></v-img>
      </div>
    </swiper-slide>
  </swiper>
</template>
<script>
import 'swiper/css/pagination'
import 'swiper/css'
import { Pagination, Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { map, find } from 'lodash'
export default {
  name: 'UiSwiperMainEvent', // 'SwiperMainEvent'
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    // items: Array,
    color: {
      type: String
    },
    hidePagination: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const onSwiper = swiper => {
      console.log(swiper)
    }
    const onSlideChange = () => {
      // console.log('slide change')
    }
    return {
      onSwiper,
      onSlideChange,
      modules: [Pagination, Autoplay]
    }
  },
  data() {
    return {
      pagination: false,
      eventList: [],
      timer: null,
      openCard: false,
      today: '99991231235959'
    }
  },
  watch: {
    hidePagination: {
      handler(val) {
        this.pagination = val ? false : { clickable: true }
      },
      immediate: true
    }
  },
  mounted() {
    // this.today = await this.getToday()
    this.getBannerList()
  },
  methods: {
    /*
    async getToday() {
      const getTodayApi = '/GW/stoplay/login/getnovasysdate'
      let today = ''
      await this.$http.get(getTodayApi, {}).then(result => {
        const res = result.data
        if (res.result.code === 'SUCCESS') {
          today = res.data
        }
      })
      return today
    },
    */
    clickItem(idx) {
      const item = this.eventList[idx]
      this.$router.push({ path: item.link1 })
    },
    getBannerList() {
      const getBannerApi = '/GW/router/STOPLAY/BRD/BrdPost/list'
      const param = {
        boardType: 'MAIN',
        tableId: 'BANNER',
        currentPage: 1,
        cntPerPage: 10,
        useYn: 'Y',
        emergenceYn: 'Y'
      }
      this.$http.post(getBannerApi, param).then(result => {
        const res = result.data
        if (res.result.code === 'SUCCESS') {
          /*
          this.eventList = filter(res.data, obj => {
            return obj.startDt <= this.today && obj.endDt >= this.today
          })
          */
          this.eventList = res.data
          map(this.eventList, obj => {
            obj.active = ''
            const img = find(obj.fileUploadClientList, { filePathSeqno: 2 })
            obj.imgUrl = '/UPLOAD' + img.fileUrl
          })
          console.log('사이드 배너 ??', this.eventList)
        } else {
          this.eventList = []
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.event-item.nth-4 {
  background-color: #057866 !important;
}
</style>
