<template>
  <v-app-bar class="header create-header">
    <div class="header-menu-left">
      <button class="prev-btn" title="이전단계" @click="cancle()"></button>
      <p class="menu-tit">{{ menuName }}</p>
    </div>

    <v-btn color="primary-normal" :disabled="!opinionModi" @click="confirm()">{{ btnTitle }}</v-btn>
  </v-app-bar>
</template>
<script>
import { isEmpty, find, isUndefined } from 'lodash'
export default {
  name: 'OpinionHeader',
  components: {},
  data() {
    return {}
  },
  computed: {
    isMobile() {
      return this.$pinia.popup.mode === 'mobile'
    },
    menuName() {
      let pageInfo = this.$router.currentRoute.value.meta
      return pageInfo.title
    },
    isAuth() {
      return this.$pinia.auth.isAuth
    },
    userData() {
      return this.$pinia.auth.userData
    },
    btnTitle() {
      const opinionType = this.$pinia.com.opinionType
      const findItem = find(this.btnList, { value: opinionType })
      if (!isEmpty(findItem)) {
        return findItem.text
      } else {
        return ''
      }
    },
    opinionAction() {
      return this.$pinia.com.opinionAction
    },
    opinionModi() {
      return this.$pinia.com.opinionModi
    },
    btnList() {
      return this.$pinia.com.opinionTitleList
    }
  },
  watch: {},
  mounted() {
    this.$pinia.com.OPINION_TITLE_UPDATE(this.$pinia.auth.getCommonCode('ST0047'))
  },
  methods: {
    activeAsideClick() {
      this.$pinia.popup.asideActive = !this.$pinia.popup.asideActive
    },
    moveLogin() {
      if (this.isAuth) {
        this.movePage('/MB/MB0200M01')
      } else {
        this.movePage('/CM/CM0100M01')
      }
    },
    movePage(path, name) {
      if (!isEmpty(path)) {
        this.$router.push({ path: path })
      } else {
        this.$router.push({ name: name, query: {}, state: {} })
      }
    },
    goBack() {
      let history = ''
      const currentRoute = this.$router.currentRoute._value
      try {
        history = this.$router.options.history.state.back
      } catch (error) {
        this.movePage('/')
      }
      if (isEmpty(history)) {
        this.movePage('/')
      } else if (currentRoute.name === 'CO0101M03' || currentRoute.name === 'CO0102M03') {
        // replace로 이동하여 작성취소 시 셋팅하여 보내줌
        const param = this.$router.options.history.state
        if (!isUndefined(param.contentsNo)) {
          this.$router.replace({ name: currentRoute.name.substr(0, 7) + '02', query: { contentsNo: param.contentsNo } })
        } else {
          this.$router.go(-1)
        }
      } else {
        this.$router.go(-1)
      }
    },
    /*
    openAlert01() {
      this.$alert('알림', '<h3>핀플채널에 의견을 남기려면<br/> 팔로잉 한 핀플채널이 있어야 해요.</h3>')
    },
    */
    cancle() {
      const _self = this
      this.$confirm('알림', this.$t('message.STC_0008'), '계속쓰기', '작성취소', undefined, '', 'out').then(res => {
        if (res === 'secondResult') {
          _self.goBack()
        }
      })
    },
    confirm() {
      this.$pinia.com.OPINION_ACTION_UPDATE(true)
    }
  }
}
</script>

<style lang="scss"></style>
