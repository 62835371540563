<template>
  <v-card class="channel-item" :class="{ banner: banner }">
    <div class="d-flex mb-3">
      <UiChips v-if="category.length > 0" :chips-list="categoryList" :items="category" />
      <div v-if="!banner && !myCard" class="my-n2">
        <v-btn v-if="follow" size="small" color="secondary" @click="setFollow('05')">
          <v-icon class="check"></v-icon>
          팔로잉
        </v-btn>
        <v-btn v-else size="small" color="primary" @click="setFollow('04')">
          <v-icon class="add"></v-icon>
          팔로우
        </v-btn>
      </div>
    </div>
    <div class="channel-name">
      {{ item.nickNm }}
      <v-icon class="finfluencer"></v-icon>
    </div>
    <div class="docu-wrap">
      <div v-dompurify-html="item.memo" class="docu"></div>
    </div>
    <div v-if="snsList.length > 0" class="sns-wrap">
      <template v-for="(sns, idx) in snsList" :key="idx">
        <v-btn icon @click="openSnsSite(sns)">
          <v-icon :class="getSnsClass(sns.snsRegisterCd)"></v-icon>
        </v-btn>
      </template>
      <v-btn v-if="!banner" icon class="ml-auto mr-n1" @click="openShare">
        <v-icon class="sns-share"></v-icon>
      </v-btn>
    </div>
    <UiSnackbar :is-open-props="isSnackbarOpen" :description="snackbarText" :open-type="snackbarType" @snack-close="closeSnackbar"></UiSnackbar>
  </v-card>
</template>
<script>
import { isEmpty, find } from 'lodash'
import { UiSnackbar } from '$$bizcomponents' // UiBottom,
import UiChips from './UiChips.vue'

import { reactive } from 'vue'
import { useAuthStore } from '@/store/index'

export default {
  name: 'UiFinInfoCard', //'ChannelItem',
  components: {
    UiChips,
    UiSnackbar
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      },
      required: true
    },
    variant: {
      type: String,
      default: ''
    }
  },
  emits: ['modify-follow'],
  setup() {
    // 공통코드 조회
    const pinia = useAuthStore()
    let codeList = pinia.getCommonCode('ST0021') // SNS 정보 공통 코드
    const snsCodeList = reactive(codeList)

    let commonList = pinia.getCommonCode('ST0042') // 핀플 카테고리 코드
    const userCardCode = reactive(pinia.getCommonCode('ST0044')) // 사용자 카드 색상 코드

    commonList = [...commonList, ...userCardCode]
    const categoryList = reactive(commonList)

    return {
      snsCodeList,
      categoryList,
      userCardCode
    }
  },
  data() {
    return {
      // category: this.item.category || [],
      banner: false,
      follow: false, // 팔로우여부
      shareYn: false,
      myCard: false,

      snsList: [],
      category: [],
      // 스낵바
      isSnackbarOpen: false,
      snackbarText: '',
      snackbarType: ''
    }
  },
  computed: {
    isAuth() {
      return this.$pinia.auth.isAuth
    }
  },
  watch: {
    item: {
      handler: function (val) {
        if (!isEmpty(val)) {
          this.setData()
        }
      },
      deep: true
    }
  },
  mounted() {
    this.isBanner()
    this.setData()
  },
  methods: {
    setData() {
      const val = this.item
      this.snsList = isEmpty(val.snsInfo) ? [] : val.snsInfo.snsList
      this.follow = val.follow
      this.myCard = val.userId === this.$pinia.auth.userData.userId
      // this.category = val.registerDefaultLevel === '03' ? ['999'] : []
      const userCard = find(this.userCardCode, { value: 'FIN' })
      this.category = [userCard.value]
      if (!isEmpty(val.chnlTopicCd)) {
        this.category.splice(0, 0, val.chnlTopicCd)
      }
    },
    isBanner() {
      if (this.variant == 'banner') {
        this.banner = true
      }
    },
    getSnsClass(snsRegisterCd) {
      const snsObj = find(this.snsCodeList, { value: snsRegisterCd })
      if (isEmpty(snsObj)) {
        return ''
      } else {
        return 'sns-' + snsObj.note + '-gray'
      }
    },
    async setFollow(type) {
      // 팔로우 처리
      if (this.isAuth) {
        const followApi = '/GW/router/STOPLAY/CMN/CmnMemberFollow/save'
        const params = {
          list: [
            {
              cmd: type === '04' ? 'I' : 'U',
              followerId: this.$pinia.auth.userData.userId,
              followingId: this.item.userId,
              followStatusCd: type
            }
          ]
        }

        await this.$http.post(followApi, params).then(res => {
          const result = res.data
          if (result.result.code === 'SUCCESS') {
            if (type === '04') {
              this.follow = true
              this.isSnackbarOpen = true
              this.snackbarType = 'follow'
              this.snackbarText = this.$t('message.STT_0042', { nickNm: this.item.nickNm })
              const emitParam = {
                result: 'success',
                type: '04',
                value: this.item
              }
              this.$emit('modify-follow', emitParam)
            } else if (type === '05') {
              this.follow = false
              this.isSnackbarOpen = true
              this.snackbarType = 'unfollow'
              this.snackbarText = this.$t('message.STT_0043', { nickNm: this.item.nickNm })
              const emitParam = {
                result: 'success',
                type: '05',
                value: this.item
              }
              this.$emit('modify-follow', emitParam)
            }
          } else {
            this.$alert('알림', result.result.clientMessage)
          }
        })
      } else {
        this.$confirm('알림', this.$t('message.STC_0004'), '취소', '로그인/회원가입', undefined).then(result => {
          if (result == 'secondResult') {
            this.$router.push({ name: 'CM0100M01' })
          }
        })
      }
    },
    openSnsSite(item) {
      const snsUrl = item.snsUrl
      this.$nativeApi.openWindow(snsUrl, '_blank')
    },
    closeSnackbar(snackbarType) {
      // 스낵바 종료
      this.isSnackbarOpen = false
      if (snackbarType === 'success') {
        // this.$modalHide(this.modalName, { result: true })
      }
    },
    openShare() {
      this.shareYn = true

      const url = 'https://' + import.meta.env.VUE_APP_DOMAIN_PC_PREFIX + '.dobu.kr/CO/CO0101M01/' + this.item.userId
      const shareResult = this.$nativeApi.showShareList('두부분식', this.$route.meta.title, url)
      if (!shareResult) {
        if (navigator.clipboard) {
          navigator.clipboard.writeText(url)
        }
        this.isSnackbarOpen = true
        this.snackbarType = 'refer'
        this.snackbarText = this.$t('message.STT_0058')
      }
    },
    closeShare() {
      this.shareYn = false
    }
  }
}
</script>
