<template>
  <v-card class="battle-item" :class="variant">
    <div class="title-wrap">
      <p class="sub-title">
        <v-icon class="vs"></v-icon>
        배틀
      </p>
      <p v-dompurify-html="battleObject.surveyTitle" class="title"></p>
    </div>
    <v-img src="/assets/common/img_battle_01.svg"></v-img>

    <v-btn-toggle v-model="battleOption" class="battle-option" :disabled="isDisabled">
      <v-btn v-for="(option, index) in battleOptionItems" :key="index" @click="selectedItem(option)">
        <p v-dompurify-html="battleObject['selectExample' + option]"></p>

        <template v-if="isBlock">
          <!-- 선택된 상태 -->
          <v-icon v-if="option == battleObject.answer" class="checkbox"></v-icon>
          <div class="value">{{ battleObject['example' + option + 'Per'] }}%</div>
        </template>
        <template v-else>
          <!-- 선택하는 화면 -->
          <v-icon v-if="battleOption == index" class="checkbox-selected ml-auto"></v-icon>
          <v-icon v-else class="checkbox ml-auto"></v-icon>
        </template>
        <template v-if="isBlock" #append>
          <!-- 퍼센테이지 여기 넣기 -->
          <div class="value-bar" :style="{ width: battleObject['example' + option + 'Per'] + '%' }"></div>
        </template>
      </v-btn>
    </v-btn-toggle>
    <div class="info">{{ numberFormat(battleObject.surveyJoinCnt) }}명 참여 중</div>
  </v-card>
</template>
<script>
// import { isEmpty } from 'lodash'
import Common from '$$utils/common'
import { isUndefined, isEmpty } from 'lodash'
export default {
  name: 'UiBattleItem', //'BattleItem',
  props: {
    variant: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: () => {
        return {}
      }
    },
    editYn: {
      type: Boolean,
      default: true
    }
  },
  emits: ['click-btn'],
  data() {
    return {
      battleObject: {
        surveyId: '',
        surveyTitle: '', // 타이틀
        selectExample1: '', // 선택값 1
        selectExample2: '', // 선택값 2
        example1Per: '', // 결과값 %
        example2Per: '', // 결과값 %
        answer: '', // 선택한 답변
        surveyJoinCnt: 0 // 참여인원
      },
      battleOption: null,
      battleOptionItems: [1, 2],
      sheet01: true,
      isBlock: false
      // disabled: false
    }
  },
  computed: {
    isAuth() {
      return this.$pinia.auth.isAuth
    },
    isSelected() {
      return !isUndefined(this.battleOption)
    },
    isDisabled() {
      // feedCard인 경우 수정이 불가
      return !this.editYn ? true : this.isBlock
    }
  },
  watch: {
    item: {
      handler: function (val) {
        this.battleObject = val
        this.isBlock = this.disabled
      },
      deep: true
    }
  },
  mounted() {
    this.battleObject = this.item
    this.isBlock = this.disabled
    if (!isEmpty(this.item.answer)) {
      this.battleOption = this.item.answer - 1 // btn option은 0,1로 구성됨
    }
    // console.log('[this.battleObject !!!]', this.battleObject)
  },
  methods: {
    selectedItem(item) {
      console.log('[battle item!!!]', item, this.battleOption)
      if (this.isAuth) {
        /*
        const result = {
          result: 'success',
          value: {
            surveyId: this.isSelected ? this.battleObject.surveyId : '',
            answer: this.isSelected ? item : null
          }
        }
        this.$emit('click-btn', result)
        */
        // TODO 배틀 저장하기
        const params = {
          list: [
            {
              cmd: 'I',
              surveyId: this.isSelected ? this.battleObject.surveyId : '',
              answer: this.isSelected ? item : null,
              tableId: this.battleObject.tableId,
              boardType: this.battleObject.boardType,
              contentsNo: this.battleObject.contentsNo,
              userId: this.$pinia.auth.userData.userId
            }
          ]
        }
        const surveyApi = '/GW/router/STOPLAY/BRD/BrdSurveyAnswer/save'
        this.$http.post(surveyApi, params).then(res => {
          const result = res.data
          if (result.result.code === 'SUCCESS') {
            console.log('[survey compleate!!!]', result)
            this.isBlock = true
            this.getFeedData()
          } else {
            this.$alert('알림', result.result.clientMessage)
          }
        })
      } else {
        this.battleOption = null
        this.$confirm('알림', this.$t('message.STC_0004'), '취소', '로그인/회원가입', undefined).then(result => {
          if (result == 'secondResult') {
            this.moveLogin()
          }
        })
      }
    },
    numberFormat(value, dotNum) {
      if (isUndefined(value)) return ''
      else {
        let dotFNum = isUndefined(dotNum) ? 0 : dotNum
        return Common.numberFormat(value, dotFNum)
      }
    },
    moveLogin() {
      this.$router.push({ name: 'CM0100M01' })
    },
    getFeedData() {
      // 게시글 내용 상세 조회
      const feedApi = '/GW/router/STOPLAY/BRD/BrdPost/list'
      const _params = {
        tableId: this.battleObject.tableId,
        boardType: this.battleObject.boardType,
        useYn: this.battleObject.useYn,
        contentsNo: this.battleObject.contentsNo
      }
      this.$http.post(feedApi, _params, { loadingOff: true }).then(async res => {
        const result = res.data
        if (result.result.code === 'SUCCESS') {
          if (!isEmpty(result.data)) {
            const feed = result.data[0]
            this.battleObject = feed
            if (!isEmpty(feed.answer)) {
              this.battleOption = feed.answer - 1 // btn option은 0,1로 구성됨
            }
          } else {
            // 데이터 미존재 시
          }
        } else {
          this.$alert('알림', result.result.clientMessage)
        }
      })
    }
  }
}
</script>
