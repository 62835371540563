<template>
  <v-footer class="footer">
    <div class="aside-area">
      <img src="/assets/main/logo_footer.svg" alt="logo:두부분식" class="logo-footer cursor-pointer" @click="movePage('/')" />

      <span v-if="smAndUp" class="corp-text cursor-pointer" @click="movePage('/CS/CS0101M01')">공지사항</span>
      <span v-if="smAndUp" class="corp-text cursor-pointer" @click="movePage('/PR/PR0200M02')">두부분식 소개</span>
      <span class="corp-text cursor-pointer" @click="movePage('/CS/CS0102M01')">이용약관</span>
      <span class="corp-text cursor-pointer" @click="movePage('/CS/CS0103M01')">고객센터</span>
      <span class="corp-text bold cursor-pointer" @click="movePage('/CS/CS0107M01')">개인정보처리방침</span>
    </div>
    <div class="copy-right" @click="nativeInterface">Copyright &copy; 하나증권 두부분식 All Right Reserved.</div>
    <!--
      <div class="copy-right">회사명: 하나증권(주) | 사업자번호: 116-81-05992</div>
    -->
    <div class="copy-right mt-1">
      두부분식에서 제공하는 투자 정보는 고객의 투자 판단을 위한 단순 참고용일 뿐, 투자 제안 및 권유 종목 추천을 위해 작성된 것이 아닙니다.
    </div>
    <!-- <div class="copy-right">Copyright &copy; 두부분식 All Right Reserved.</div> -->
    <div class="sns-area">
      <img src="/assets/main/logo_telegram.svg" alt="텔레그램" class="footer-sns-logo cursor-pointer" @click="openSns(teleUrl)" />
      <img src="/assets/main/logo_instagram.svg" alt="인스타그램" class="footer-sns-logo cursor-pointer" @click="openSns(instaUrl)" />
    </div>

    <!-- <div class="text-center w-100">{{ new Date().getFullYear() }} — <strong>FNF Platform</strong></div> -->
  </v-footer>
</template>

<script>
import DeviceUtil from '$$utils/device'
import { isEmpty } from 'lodash'
import nativeInterface from '@/views/CM/nativeInterface.vue'
import { useDisplay } from 'vuetify'

export default {
  name: 'FooterPage',
  components: {},
  setup() {
    const { smAndUp } = useDisplay()
    return {
      smAndUp
    }
  },
  data() {
    return {
      teleUrl: 'https://t.me/dobuannouncement',
      instaUrl: 'https://instagram.com/dobuboonsik',
      clickCount: 0
    }
  },
  computed: {
    isDesktop() {
      return DeviceUtil.isDesktop()
    }
  },
  methods: {
    movePage(path, name) {
      if (!isEmpty(path)) {
        this.$router.push({ path: path })
      } else {
        this.$router.push({ name: name, query: {}, state: {} })
      }
    },
    openSns(snsUrl) {
      this.$nativeApi.openWindow(snsUrl, '_blank')
    },
    nativeInterface() {
      if (import.meta.env.VUE_APP_BUILD_MODE !== 'PRODUCTION') {
        this.clickCount++
        if (this.clickCount === 2) {
          const params = { modalName: 'CM9901L99', activeBtnClose: true, isTitle: true, headerClass: 'modal-popup-header' }
          this.$modalOpen('앱 Interface', nativeInterface, { params: params })
          this.clickCount = 0
        }
      }
    }
  }
}
</script>
<style lang="scss"></style>
