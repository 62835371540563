<template>
  <swiper
    class="swiper-point"
    :modules="modules"
    :space-between="10"
    :autoplay="{
      delay: 2500,
      disableOnInteraction: false
    }"
    @init="initSwiper"
    @swiper="onSwiper"
    @slide-change="onSlideChange"
  >
    <swiper-slide v-for="item in swiperItems" :key="item.id">
      <v-btn class="point-item">
        <UiUserImg :data="item" :img-type="'finfle'"></UiUserImg>
        <p v-dompurify-html="item.nickNm" class="name"></p>
        <p class="value">{{ numberFormat(item.point, 0) }}모</p>
      </v-btn>
    </swiper-slide>
  </swiper>
</template>
<script>
import 'swiper/css/pagination'
import 'swiper/css'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Autoplay } from 'swiper/modules'
import { UiUserImg } from '$$bizcomponents'

import { isUndefined } from 'lodash'
import Common from '$$utils/common'
export default {
  name: 'UiSwiperPoint', // 'SwiperPoint',
  components: {
    Swiper,
    SwiperSlide,
    UiUserImg
  },
  props: {
    items: {
      type: Array,
      default: () => {
        return []
      }
    },
    color: {
      type: String,
      default: ''
    }
  },
  setup() {
    const onSwiper = swiper => {
      console.log(swiper)
    }
    const onSlideChange = () => {
      // console.log('slide change')
    }
    return {
      onSwiper,
      onSlideChange,
      modules: [Autoplay]
    }
  },
  data() {
    return {
      swiperInstance: undefined,
      swiperItems: []
    }
  },
  watch: {
    items: {
      handler(newVal) {
        console.log('!!! Updated items:', newVal)
        this.swiperItems = newVal
        if (this.swiperInstance) {
          this.swiperInstance.update()
          this.swiperInstance.slideTo(0)
        }
      },
      deep: true
    }
  },
  mounted() {
    this.swiperItems = this.items
  },
  methods: {
    initSwiper(instance) {
      this.swiperInstance = instance
    },
    numberFormat(value, dotNum) {
      if (isUndefined(value)) return ''
      else {
        let dotFNum = isUndefined(dotNum) ? 0 : dotNum
        return Common.numberFormat(value, dotFNum)
      }
    }
  }
}
</script>
