<template>
  <v-img v-if="imgType === 'profile'" :class="imgClass" :src="setUserIcon(data)" :alt="alt" cover />
  <v-img v-else-if="imgType === 'finfle'" :class="imgClass" :src="setUserIcon(data)" :draggable="draggable" cover />
</template>

<script>
export default {
  name: 'UiUserImg',
  mixins: [],
  props: {
    title: {
      type: String,
      default: '자기소개 문구'
    },
    data: {
      type: Object,
      default: () => {}
    },
    alt: {
      type: String,
      default: 'profileImg'
    },
    imgClass: {
      type: String,
      default: 'my-profile'
    },
    imgType: {
      type: String,
      default: 'profile'
    },
    draggable: {
      type: Boolean,
      default: false
    }
  },
  emits: [],
  data() {
    return {}
  },
  computed: {
    // console.log('UICommonModal computed')
  },
  watch: {
    // console.log('UICommonModal watch')
  },
  mounted() {},
  updated() {
    // console.log('UICommonModal updated')
  },
  unmounted() {
    // console.log('UICommonModal unmounted')
  },
  methods: {
    setUserIcon(data) {
      const imgUrl = this.$pinia.auth.getUserIconSrc(data.profileImageCd, data.profileImageCut)
      return imgUrl
    }
  }
}
</script>
<style lang="scss"></style>
