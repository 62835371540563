<template>
  <v-tabs
    ref="scrollTabs"
    v-model="value"
    :class="[className, { 'hide-arrows': !showArrows }]"
    :show-arrows="showArrows"
    @mousedown="startDrag"
    @mousemove="onDrag"
    @mouseup="stopDrag"
    @mouseleave="stopDrag"
  >
    <v-tab v-for="(item, index) in items" :key="index" :value="className == 'finple-tabs' ? index : item.value">
      <UiUserImg v-if="showImg" :key="index" :data="item" :img-class="''" :img-type="'finfle'"></UiUserImg>
      <v-icon v-if="showImg && item.userId === userId" class="check-white"></v-icon>
      <p class="name" :class="{ 'text-primary': index == 0 && className == 'finple-tabs' }">
        {{ className == 'finple-tabs' ? item.nickNm : item.text }}
      </p>
    </v-tab>
    <v-btn v-if="showMore" class="more" @click="movePage">
      <v-icon class="arrow-right"></v-icon>
      <p class="name">더보기</p>
    </v-btn>
  </v-tabs>
  <v-divider v-if="className === 'type01'" class="type01"></v-divider>
</template>
<script>
import { UiUserImg } from '$$bizcomponents'
export default {
  name: 'UiScrollTabs', // 'ScrollTabs',
  components: { UiUserImg },
  props: {
    modelValue: {
      type: [String, Number]
    },
    items: {
      type: Array
    },
    className: {
      type: String
    },
    showMore: {
      type: Boolean,
      default: false
    },
    showArrows: {
      type: Boolean,
      default: false
    },
    showImg: {
      type: Boolean,
      default: false
    },
    morePath: {
      type: String,
      default: ''
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      innerValue: 0,
      isDragging: false,
      startX: 0,
      scrollLeft: 0,
      scrollContainer: null
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue ?? this.innerValue
      },
      set(value) {
        this.innerValue = value
        this.$emit('update:modelValue', value)
      }
    },
    userId() {
      return this.$pinia.auth.userData.userId
    }
  },
  mounted() {
    const tabsEl = this.$refs.scrollTabs
    if (tabsEl) {
      this.scrollContainer = tabsEl.$el.nextElementSibling.querySelector('.v-slide-group__container')
    }
  },
  methods: {
    startDrag(e) {
      if (!this.scrollContainer) return
      this.isDragging = true
      this.startX = e.pageX - this.scrollContainer.offsetLeft
      this.scrollLeft = this.scrollContainer.scrollLeft
    },
    onDrag(e) {
      if (!this.isDragging || !this.scrollContainer) return
      e.preventDefault()
      const x = e.pageX - this.scrollContainer.offsetLeft
      const walk = (x - this.startX) * 1.5
      this.scrollContainer.scrollLeft = this.scrollLeft - walk
    },
    stopDrag() {
      this.isDragging = false
    },
    movePage() {
      this.$router.push({ path: this.morePath })
    }
  }
}
</script>
