<template>
  <div class="apply-result-wrap">
    <v-img :src="item.imgUrl"></v-img>
    <p v-dompurify-html="item.title" class="title"></p>
    <div v-dompurify-html="item.docu" class="docu"></div>
    <div v-if="item.info" v-dompurify-html="item.info" class="info"></div>
  </div>

  <div class="btn-wrap mt-8">
    <v-btn color="primary" @click="handleButtonClick">{{ item.btn }}</v-btn>
  </div>
</template>

<script>
export default {
  name: 'UiApplyResult',
  props: {
    value: {
      type: Number,
      default: 0
    },

    userData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      nickNm: '',
      items: [
        {
          imgUrl: '../assets/common/img_finple_apply_01.svg',
          title: '핀플이 되신 것을 축하드려요!',
          docu: '두부두부님의 핀플채널이 생겼어요.<br/>앞으로 적극적인 핀플 활동 부탁드려요!',
          info: '3개월 내 팔로잉 100명을 달성 하지 못하면<br/>직전 등급으로 내려가요',
          btn: '내 핀플채널 보기'
        },
        {
          imgUrl: '../assets/common/img_finple_apply_02.svg',
          title: '심사 중이예요.',
          docu: '두부두부님의 핀플 신청을 심사 중이예요.<br/>잠시만 기다려 주세요.',
          btn: '확인'
        },
        {
          imgUrl: '../assets/common/img_finple_apply_03.svg',
          title: '다음에 다시 만나요~',
          docu: '신청해 주셔서 감사합니다.<br/>두부두부님은 충분히 훌륭한 우리 VIP회원이지만,<br/>이번엔 인연이 닿지 않았어요.<br/>한달 뒤에 꼭! 다시 신청해주세요~',
          btn: '확인'
        }
      ]
    }
  },

  computed: {
    item() {
      console.log('현재 item value:', this.value)
      return this.items[this.value]
    }
  },

  mounted() {
    this.nickNm = this.$pinia.auth.userData.nickNm
    console.log('UiApplyResult >> nickNm :', this.nickNm)

    // 닉네임 두부두부 >> this.nickNm
    this.items.forEach(item => {
      item.docu = item.docu.replace('두부두부', this.nickNm)
    })
  },

  methods: {
    handleButtonClick() {
      if (this.value === 0) {
        // 내 핀플채널 보기 클릭
        console.log('내 핀플채널 보기 클릭')
        this.$router.push({ path: '/CO/CO0101M01/' + this.userData.userId })
      } else {
        // 확인 클릭
        console.log('확인 클릭')
        this.$router.push({ path: '/CO/CO0100M01' })
      }
    }
  }
}
</script>
