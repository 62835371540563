<template>
  <v-layout>
    <router-view name="gnb"></router-view>
    <div class="wrap responsive">
      <router-view name="aside"></router-view>
      <router-view name="header"></router-view>
      <router-view v-slot="{ Component }" :key="$route.fullPath" name="content">
        <component :is="Component" ref="content"></component>
      </router-view>
      <router-view id="quickMenu" name="quick"></router-view>
      <router-view name="footer"></router-view>
    </div>
    <router-view name="sideContent"></router-view>
  </v-layout>
</template>

<script>
export default {
  name: 'IndexPage',
  components: {},
  setup() {},
  data() {
    return {}
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {}
}
</script>
<style lang="scss"></style>
