import CM from './CM/route'
import MB from './MB/route'
import CS from './CS/route'
import PD from './PD/route'
import PR from './PR/route'
import CO from './CO/route'
import NO from './NO/route'
import { isEmpty } from 'lodash'
import { AbstractRoute } from '@/views/layout'

const routes = [
  {
    path: '',
    component: AbstractRoute,
    children: [].concat(CM, MB, CS, PD, PR, CO, NO)
  }
]

// console.log('[route] routes', routes)
let newRoute = []
let AbstractRouteName = ''

const routeLevelCompute = function (routes, pathName) {
  for (let i = 0; i < routes.length; i++) {
    let routeComponentName = ''
    // console.log('typeof routes[i].component.name', typeof routes[i].component)
    if (typeof routes[i].component !== 'undefined') {
      routeComponentName = routes[i].component.name
      // console.log('routes[i].component.name', routes[i].component.name)
    }
    // console.log('routes[i].component.name, routeComponentName, AbstractRouteName', routeComponentName, AbstractRouteName, isEmpty(routes[i].children))
    if (routes[i].component.name === 'AbstractRoute' || routeComponentName === AbstractRouteName) {
      routeLevelCompute(routes[i].children, pathName + '/' + routes[i].path)
    } else {
      routes[i].path = pathName + '/' + routes[i].path
      newRoute.push(routes[i])
    }
  }
}

if (isEmpty(routes[0].component.name)) {
  newRoute = routes
} else {
  if (routes[0].children.length > 0) {
    const oldRouter = routes[0].children
    AbstractRouteName = routes[0].component.name
    // console.log('AbstractRouteName', AbstractRouteName)
    routeLevelCompute(oldRouter, '')
    // console.log('routeLevelCompute', newRoute)
  }
}

// console.log('[route] newRoute', newRoute)
export default newRoute
