import axios from 'axios'
// import { isEmpty, filter, find } from 'lodash'
// import { event } from 'vue-gtag'

export default {
  state: () => ({
    isAlarm: false
  }),
  getters: {},
  actions: {
    ACTIVE_ALARM() {
      const _self = this
      return new Promise(function (resolve) {
        const apiPath = '/GW/router/STOPLAY/CMN/CmnAlarm/existAlarm'
        axios.post(apiPath, { checkYn: 'N' }).then(result => {
          const res = result.data
          if (res.result.code === 'SUCCESS') {
            if (res.data > 0) {
              _self.isAlarm = true
            } else {
              _self.isAlarm = false
            }
            resolve(res)
          } else {
            // 실패로직
            _self.isAlarm = false
            resolve(res)
          }
        })
      })
    }
  }
  /*
  persist: {
    paths: [],
    storage: sessionStorage,
    beforeRestore: ctx => {
      console.log(`store start '${ctx.store.$id}'`)
    },
    afterRestore: ctx => {
      console.log(`store complete '${ctx.store.$id}'`)
    }
  }
  */
}
