<template>
  <div class="comment-write-wrap">
    <v-text-field
      ref="commentRef"
      v-model="comment"
      variant="outlined"
      class="input-item"
      clearable
      placeholder="댓글입력"
      :maxlength="200"
      counter
      hint="이 의견에 댓글을 남기는 중"
      @focus="focusIn"
      @blur="focusOut"
      @keyup.enter="commentEnter"
    >
      <!-- hide-details="auto" -->
      <template #append-inner>
        <v-btn size="x-small" color="black" :disabled="!comment" @click="saveComment">남기기</v-btn>
      </template>
    </v-text-field>
    <UiSnackbar
      :is-open-props="commentSnackOpen"
      :description="commentSnackText"
      :open-type="commentsnackType"
      @snack-close="closeSnackbar"
    ></UiSnackbar>
  </div>
</template>
<script>
import { escape } from 'lodash'
import { UiSnackbar } from '$$bizcomponents'
import DeviceUtil from '$$utils/device'
export default {
  name: 'UiCommentWrite', // 'CommentWrite',
  components: {
    UiSnackbar
  },
  props: {
    tableId: {
      type: String,
      default: ''
    },
    contentsNo: {
      type: Number,
      default: 0
    },
    focusComment: {
      type: Boolean,
      default: false
    },
    parentId: {
      type: Number,
      default: 0
    },
    battleObj: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  emits: ['confirm', 'focut-out'],
  data() {
    return {
      comment: '',
      focusCm: false,
      parent: '',
      saveErrorYn: false,
      // 20241226 주석 battleItem: {},
      // snackbar
      commentSnackOpen: false,
      commentSnackText: '',
      commentsnackType: ''
    }
  },
  computed: {
    isAuth() {
      return this.$pinia.auth.isAuth
    }
  },
  watch: {
    focusComment(val) {
      this.focusCm = val
    },
    focusCm(val) {
      if (val) {
        this.$refs.commentRef.focus()
      }
    },
    parentId(val) {
      this.parent = val
    }
    /* 20241226 주석 
    battleObj: {
      handler: function () {
        this.battleItem = this.battleObj
      },
      deep: true
    }
    */
  },
  methods: {
    commentBlur() {
      this.$refs.commentRef.blur()
    },
    focusOut() {
      this.$emit('focut-out', { result: 'success', saveError: this.saveErrorYn })
    },
    focusIn() {
      this.saveErrorYn = false
      if (!this.isAuth) {
        this.commentBlur()
        this.$confirm('알림', this.$t('message.STC_0004'), '취소', '로그인/회원가입', undefined).then(result => {
          if (result == 'secondResult') {
            this.$router.push({ name: 'CM0100M01' })
          }
        })
      }
    },
    saveComment() {
      if (this.isAuth) {
        // 댓글 저장
        const commentSaveApi = '/GW/router/STOPLAY/BRD/BrdPostComment/save'
        // 20241226 주석 const battleObj = isEmpty(this.battleItem) ? {} : this.battleItem.value
        const param = {
          cmd: 'I',
          tableId: this.tableId,
          contentsNo: this.contentsNo,
          parCommentNo: this.parent !== 0 ? this.parent : '', // 상위 댓글 ID
          contents: escape(this.comment)
          // 20241226 주석 ...battleObj
        }
        const _params = {
          list: [param]
        }

        this.$http.post(commentSaveApi, _params).then(res => {
          const result = res.data
          if (result.result.code === 'SUCCESS') {
            // console.log('[댓글 저장!!!]', result.data)
            const res = {
              result: 'success',
              value: param,
              data: result.data
            }
            this.$emit('confirm', res)
            this.comment = ''
          } else {
            // focusOut 처리....
            this.saveErrorYn = true
            if (result.result.code === 'CE_99018') {
              const jsonObj = JSON.parse(result.result.clientMessage)
              this.$alert('알림', this.$t('message.STE_0021', { val: jsonObj.value })) // '금칙어가 포함되어있습니다.(' + jsonObj.value + ')')
            } else {
              this.$alert('알림', result.result.clientMessage)
            }
          }
        })
      } else {
        this.$confirm('알림', this.$t('message.STC_0004'), '취소', '로그인/회원가입', undefined).then(result => {
          if (result == 'secondResult') {
            this.$router.push({ name: 'CM0100M01' })
          }
        })
      }
    },
    closeSnackbar() {
      this.commentSnackOpen = false
    },
    commentEnter() {
      if (DeviceUtil.isDesktop()) {
        this.saveComment()
      }
    }
  }
}
</script>
