<template>
  <div v-if="isOpinionYn" class="floating-action-wrap" :class="{ active: windowTop < 100 }">
    <v-btn icon class="floating-action-btn" @click="moveWriteFeed">
      <v-icon class="plus"></v-icon>
      <p>의견등록</p>
    </v-btn>
  </div>
  <v-btn v-if="quickShow" class="topscroll" @click="goTop()">
    <v-icon dark> mdi-arrow-up</v-icon>
  </v-btn>
</template>
<script>
import { isEmpty, map } from 'lodash'

export default {
  name: 'ComQuickMenu',
  components: {},
  inheritAttrs: false,
  props: {
    expand: {
      type: Boolean,
      default: null
    }
  },
  emits: ['clickCreate', 'updateExpand'],
  setup() {},
  data() {
    return {
      isExpand: false,
      hideAction: null,
      openCom: false,
      windowTop: 0,

      commuTypeList: []
    }
  },
  computed: {
    isAuth() {
      return this.$pinia.auth.isAuth
    },
    siteType() {
      return this.$pinia.com.siteType
    },
    toggleMenu: {
      get() {
        return this.expand ?? this.isExpand
      },
      set(value) {
        this.isExpand = value
        this.$emit('updateExpand', value)
      }
    },
    quickShow() {
      if (this.windowTop === 0) return false
      else return true
    },
    isOpinionYn() {
      return this.$pinia.com.isOpinionYn
    },
    loginUser() {
      return this.$pinia.auth.userData
    }
  },
  watch: {},
  mounted() {
    // 공통코드 조회
    this.commuTypeList = this.$pinia.auth.getCommonCode('ST0054') // 커뮤니티 종류 코드
    map(this.commuTypeList, obj => {
      obj.icon = obj.note
    })

    window.addEventListener('scroll', this.onScroll) // TOP일때 quickMenu 제거
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.onScroll)
  },
  unmounted() {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    openLogin() {
      this.$confirm('알림', this.$t('message.STC_0004'), '취소', '로그인/회원가입', undefined).then(result => {
        if (result == 'secondResult') {
          this.$router.push({ name: 'CM0100M01' })
        }
      })
    },
    movePage(path, param) {
      // this.closeMenu()
      this.$router.push({ path: path, state: param })
    },
    selectedType(item) {
      // console.log('커뮤니티 타입 선택!!!', item)
      this.movePage(item.detail)
    },
    onScroll() {
      this.windowTop = window.top.scrollY
    },
    goTop() {
      const moveItem = document.getElementsByClassName('v-layout')
      if (!isEmpty(moveItem)) {
        moveItem[0].scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    },
    toggleAction() {
      this.hideAction = this.toggleMenu
      this.toggleMenu = !this.toggleMenu
    },
    handleClickCreate() {
      this.closeMenu()
      this.$emit('clickCreate')
    },
    closeMenu() {
      if (this.isExpand) {
        this.hideAction = true
        this.isExpand = false
      }
    },
    moveWriteFeed() {
      // 이동시키기
      console.log('siteType', this.siteType)
      if (this.isAuth) {
        if (this.siteType === 'OPEN') {
          this.$router.push({ path: '/CO/CO0102M03' })
        } else if (this.loginUser.registerDefaultLevel === 3 && (this.siteType === 'ALL' || this.siteType === 'MY')) {
          this.$router.push({ path: '/CO/CO0101M03' })
        }
      } else {
        this.openLogin()
      }
    }
  }
}
</script>
<style lang="scss"></style>
