<template>
  <v-bottom-sheet
    v-model="isOpenYn"
    class="ui-bottom"
    :transition="smAndUp ? 'fade-transition' : 'bottom-sheet-transition'"
    persistent
    no-click-animation
  >
    <v-card title="SNS 정보 입력" class="sns-select px-0 py-0 text-left">
      <div class="close-btn" @click="closeSheet()"></div>
      <div class="wrap-scroll">
        <div class="comtent-btn snsbtn">
          <div class="tit mb-02"><span>SNS 선택</span></div>
          <button v-for="(item, index) in dataList" :key="index" :class="getSnsClass(item)" @click="onClickSnsCode(item)">{{ item.text }}</button>
        </div>
        <!-- //직접입력일때 나타남  -->
        <div v-if="selectSnsCd == 'DIR'" class="w-100 d-flex py-0 mt-4 sns-input">
          <v-text-field
            id="snsName"
            ref="snsNameRef"
            v-model="snsName"
            name="snsName"
            class="input"
            variant="outlined"
            maxlength="20"
            :rules="[rules.snsName, rules.snsNameForbiddenChk]"
            placeholder="SNS 명칭을 입력해주세요."
            @update:model-value="snsNameValueChange(e)"
          ></v-text-field>
        </div>
        <!-- //직접입력일때 나타남  -->

        <p class="tit mt-6 mb-3"><span>SNS 주소</span></p>
        <div class="w-100 btn-floor d-flex">
          <v-textarea
            id="snsUrl"
            ref="snsUrlRef"
            v-model="snsUrl"
            variant="outlined"
            name="snsUrl"
            rows="2"
            maxlength="200"
            counter
            :rules="[rules.snsUrl, rules.snsUrlForbiddenChk]"
            :placeholder="placeholderText"
            @update:model-value="snsUrlValueChange(e)"
          ></v-textarea>
        </div>

        <ul v-if="desc.length > 0" class="comtent-self-text">
          <li v-for="(item, index) in desc" :key="index">{{ item }}</li>
        </ul>
      </div>
    </v-card>
    <div class="btn-floor">
      <button class="c-btn c-btn-purple bg-light" :class="isSnsYn() ? 'disable' : ''" :disabled="isSnsYn()" @click="save()">적용하기</button>
    </div>
  </v-bottom-sheet>
</template>

<script>
import { find, isEmpty, escape, unescape } from 'lodash'
import { useI18n } from 'vue-i18n'
import Common from '$$utils/common'

import { useField, useForm } from 'vee-validate'
import * as yup from 'yup'
import { useDisplay } from 'vuetify'

export default {
  name: 'UiBottomList',
  mixins: [],
  props: {
    title: {
      type: String,
      default: 'SNS 정보 입력'
    },
    list: {
      type: Array,
      default: () => []
    },
    isOpenProps: {
      type: Boolean,
      default: false
    },
    description: {
      type: [String, Array],
      default: ''
    },
    initObj: {
      type: Object,
      default: () => {}
    }
  },
  emits: ['btm-close', 'btm-list-save', 'btm-props-change', 'btm-sns-snackbar'],
  setup() {
    // i18n 객체를 선언한다 : 다국어
    const { t } = useI18n()

    // 현재 페이지의 전체 validate 객체를 선업합니다
    const validationSchema = yup.object({
      snsName: yup
        .string()
        .required(/*t('validate.required')*/ 'SNS 명칭을 입력해주세요.')
        .max(20, '최대 20자까지 등록이 가능합니다.' /*t('validate.max', { max: 16 })*/),
      snsUrl: yup
        .string()
        .required(/*t('validate.required')*/ 'SNS 상세정보를 입력해주세요.')
        .max(200, '최대 200자까지 등록이 가능합니다.' /*t('validate.max', { max: 16 })*/)
    })
    // 전역 에러 체크 변수 및 함수를 생성한다***
    const { errors, validate, validateField } = useForm({
      validationSchema: validationSchema
    })
    // vuetyfy에서 사용할 수 있는 함수를 선언한다
    const vuetifyVaild = async vaildFn => {
      const result = await vaildFn()
      if (result.valid) return true
      else return result.errors[0]
    }
    // 항목별 validate 변수 및 함수를 선언한다
    const { value: snsName, validate: snsNameChange } = useField('snsName', validateField('snsName'))
    const { value: snsUrl, validate: snsUrlChange } = useField('snsUrl', validateField('snsUrl'))
    const { smAndUp } = useDisplay()
    return {
      t,
      errors,
      validate,
      snsName,
      snsNameChange,
      snsUrl,
      snsUrlChange,
      vuetifyVaild,
      smAndUp
    }
  },
  data() {
    return {
      rules: {
        snsName: async () => {
          if (!this.snsBtnClick) {
            return true
          } else {
            const result = await this.vuetifyVaild(this.snsNameChange)
            return result
          }
        },
        snsUrl: async () => {
          if (!this.snsBtnClick) {
            return true
          } else {
            const result = await this.vuetifyVaild(this.snsUrlChange)
            return result
          }
        },
        snsNameForbiddenChk: () => {
          if (!this.snsBtnClick) {
            return true
          } else {
            return this.snsNameForbidden || this.$t('message.STE_0003')
          }
        },
        snsUrlForbiddenChk: () => {
          if (!this.snsBtnClick) {
            return true
          } else {
            return this.snsUrlForbidden || this.$t('message.STE_0003')
          }
        }
      },
      // contentsReg: /^[^><()]*$/,
      // 금지어 validation 변수
      snsNameForbidden: true,
      snsUrlForbidden: true,

      dataList: [],
      selectedList: [],
      isOpenYn: false,
      desc: [],
      snsBtnClick: false,
      selectSnsCd: '',

      isSnsUrl: false,
      isSnsName: false,
      snsCmd: 'I',
      initSnsObj: {},
      userId: '',
      placeholderText: '유튜브 주소를 입력해 주세요.', // 초기 placeholderText 설정
      // snackbar
      isSnackbarOpen: false,
      snackbarText: '',
      snackbarType: ''
    }
  },

  computed: {
    // console.log('UICommonModal computed')
  },
  watch: {
    // console.log('UICommonModal watch')
    list: {
      handler: function () {
        this.dataList = [...this.list]
        // this.placeholderText = this.dataList[0].text + ' 주소를 입력해 주세요.'
      },
      deep: true
    },
    isOpenProps() {
      this.isOpenYn = this.isOpenProps
    },
    initObj: {
      handler: function () {
        this.initSnsObj = this.initObj
        // if (this.isOpenYn) {
        this.settingSnsObj()
        // }
      },
      deep: true
    },
    isOpenYn() {
      if (this.isOpenProps && !this.isOpenYn) this.changeOpenPros()
    }
  },
  mounted() {
    this.userId = this.$pinia.auth.userData.userId

    if (typeof this.desc === 'object') {
      this.desc = [...this.description]
    } else {
      this.desc.push(this.description)
    }
  },
  updated() {
    // console.log('UICommonModal updated')
  },
  unmounted() {
    // console.log('UICommonModal unmounted')
  },
  methods: {
    settingSnsObj() {
      // SNS 편집창 open
      if (isEmpty(this.initSnsObj)) {
        // 신규
        this.snsUrl = ''
        this.initSnsObj = {}
        this.selectSnsCd = this.dataList[0].value
        this.snsName = this.dataList[0].text
        this.snsCmd = 'I'
        this.isSnsName = true
        this.placeholderText = '유튜브 주소를 입력해 주세요.'
      } else {
        // 기존
        this.selectSnsCd = this.initSnsObj.snsRegisterCd
        this.snsName = this.initSnsObj.snsName
        this.snsUrl = unescape(this.initSnsObj.snsUrl)
        this.snsCmd = 'U'
        this.isSnsUrl = false
        if (this.selectSnsCd != 'DIR') this.isSnsName = true
      }
      this.snsBtnClick = false
      this.snsUrlValueChange()
      this.snsNameValueChange()
    },
    getSnsNm(snsCd, snsNm) {
      // sns명칭 셋팅
      // console.log('[getSnsNm!!!]', snsCd, snsNm)
      const snsObj = find(this.snsCodeList, { value: snsCd })
      if (isEmpty(snsObj)) {
        return snsCd
      } else if (snsCd == 'DIR') {
        return snsNm
      } else {
        return snsObj.text
      }
    },
    onClickSnsCode(item) {
      // sns code Click
      console.log('[onClickSnsCode]', item)
      this.selectSnsCd = item.value
      if (item.value != 'DIR') {
        this.isSnsName = true
        this.snsName = item.text
        this.placeholderText = `${item.text} 주소를 입력해 주세요.`
      } else {
        this.isSnsName = false
        this.snsName = ''
      }
    },
    getSnsClass(item) {
      // sns class
      if (item.value == this.selectSnsCd) return 'on'
      else ''
    },
    isSnsYn() {
      if (this.snsCmd == 'I') {
        if (this.isSnsName && this.isSnsUrl) return false
        else return true
      } else {
        if (this.snsName !== this.initSnsObj.snsName) return false
        else if (this.snsUrl !== this.initSnsObj.snsUrl) return false
        else return true
      }
    },
    snsNameValueChange() {
      // snsNm value change
      this.snsBtnClick = false
      this.snsNameForbidden = false
      if (isEmpty(this.snsName)) this.isSnsName = false
      else this.isSnsName = true
    },
    snsUrlValueChange() {
      // snsUrl value change
      this.snsBtnClick = false
      this.snsUrlForbidden = false
      if (isEmpty(this.snsUrl)) this.isSnsUrl = false
      else this.isSnsUrl = true
    },
    /** SNS finish */
    async dataValidation(id) {
      // 단일 항목 validation
      let result = await this.$refs[id + 'Ref'].validate()
      if (result.length > 0) {
        this.$refs[id + 'Ref'].focus()
      }
      return result
    },
    async save() {
      // 적용하기 클릭
      this.snsBtnClick = true
      let nameResult = []
      let urlResult = []
      let urlValidResult = false
      // let textResult = false
      if (this.selectSnsCd == 'DIR') {
        this.snsNameForbidden = await this.$pinia.auth.forbiddenValidation(this.snsName)
        nameResult = await this.dataValidation('snsName')
      }
      this.snsUrlForbidden = await this.$pinia.auth.forbiddenValidation(this.snsUrl)
      urlResult = await this.dataValidation('snsUrl')

      /*
      textResult = this.contentsReg.test(this.snsUrl) && this.contentsReg.test(this.snsName)
      if (!textResult) {
        this.$emit('btm-sns-snackbar', { open: true, type: 'txt', text: this.$t('message.STT_0059', { val: '<>()' }) })
        return false
      }
      */

      await this.urlValidation(this.selectSnsCd, this.snsUrl).then(result => {
        console.log('[urlValidation !!!]', result)
        urlValidResult = result.result
        if (result.code === 'NOTFOUND') {
          this.$emit('btm-sns-snackbar', { open: true, type: 'urlfail', text: '잠시 후 다시 시도해주세요.' })
        } else if (result.code === 'URLVALID' && !urlValidResult) {
          this.$emit('btm-sns-snackbar', { open: true, type: 'urlfail', text: this.$t('message.STT_0049', { url: result.url }) })
        }
      })

      if (nameResult.length == 0 && urlResult.length == 0 && urlValidResult) {
        let addSnsData = {
          cmd: this.snsCmd,
          memberSnsNo: 'new_' + Common.randomValue(89999),
          userId: this.userId,
          snsName: this.snsName,
          snsUrl: escape(this.snsUrl),
          snsRegisterCd: this.selectSnsCd,
          useYn: 'Y'
        }
        this.$emit('btm-list-save', addSnsData)
        this.resetData()
      }
    },

    async urlValidation(snsType, url) {
      return new Promise((resolve, reject) => {
        const findObj = find(this.dataList, { value: snsType })
        if (isEmpty(findObj)) {
          return reject({ result: false, code: 'NOTFOUND', url: '' })
        }
        const urlIndex = url.indexOf(findObj.detail)
        const urlValid = urlIndex > -1
        return resolve({ result: urlValid, code: 'URLVALID', url: findObj.detail })
      })
    },

    closeSheet() {
      this.$emit('btm-close')
    },
    changeOpenPros() {
      this.resetData()
      this.placeholderText = '유튜브 주소를 입력해 주세요.' // placeholder 초기화
      this.$emit('btm-props-change')
    },

    resetData() {
      this.initSnsObj = {}
      this.isSnsUrl = false
      this.isSnsName = false
      this.snsBtnClick = false
    }
  }
}
</script>
<style lang="scss"></style>
