import axios from 'axios'
import { isNaN } from 'lodash'
// import device from '$$utils/device'
import common from '$$utils/common'
import DOMPurify from 'dompurify'

const Plugins = {}
Plugins.install = function (Vue) {
  // , pinia
  Vue.$novaCom = Vue.config.globalProperties.$novaCom = {
    sanitize(html) {
      const config = { FORBID_TAGS: ['form', 'script', 'a', 'input', 'iframe', 'object', 'applet', 'embed', 'meta'] }
      const purify = DOMPurify()
      return purify.sanitize(html, config)
    },
    // 유투브 컨텐츠ID 추출함수
    filteredUrlId(allUrl) {
      if (allUrl.indexOf('/watch') > -1) {
        const arr = allUrl.replaceAll(/=|&/g, '?').split('?')
        return arr[arr.indexOf('v') + 1]
      } else if (allUrl.indexOf('/youtu.be') > -1) {
        const arr = allUrl.replaceAll(/=|&|\//g, '?').split('?')
        return arr[arr.indexOf('youtu.be') + 1]
      } else {
        return
      }
    },
    // 랜덤변수 생성
    randomValue: function (modVar) {
      return common.randomValue(modVar)
    },
    /**
     * INPUT 내용 체크
     * @param obj 입력된 내용
     * @return obj >< 특수문자 제거 내용
     */
    checkInputWord: function (obj) {
      if (obj.length > 0) {
        // 특수문자 제거
        const expText = /[><]/
        if (expText.test(obj)) {
          obj = obj.replaceAll(/[><]/g, '')
        }
      }
      return obj
    },
    /**
     * 검색내용에 위험문자 알림
     * @param obj 입력된 내용
     * @return true: 안전한 내용, false: 위험한 내용
     */
    checkSearchedWord: function (obj) {
      if (obj.length > 0) {
        // 특수문자 제거
        const expText = /[%=><]/
        if (expText.test(obj)) {
          Vue.$alert('알림', '특수문자를 입력 할수 없습니다').then(() => {
            return false
          })
        }
        // 특정문자열(sql예약어의 앞뒤공백포함) 제거
        const sqlArray = ['OR', 'SELECT', 'INSERT', 'DELETE', 'UPDATE', 'CREATE', 'DROP', 'EXEC', 'UNION', 'FETCH', 'DECLARE', 'TRUNCATE']
        let regex
        for (let i = 0; i < sqlArray.length; i++) {
          regex = new RegExp(sqlArray[i], 'gi')
          if (regex.test(obj)) {
            Vue.$alert('알림', sqlArray[i] + '와(과) 같은 특정문자로 검색할 수 없습니다.').then(() => {
              return false
            })
          }
        }
      }
      return true
    },
    /**
     * window width 계산 함수
     * @param 없음
     * @return winWidth 현재 환경에서 제공하는 window width
     */
    windowWidth: function () {
      let winWidth = 0
      if (!window.innerWIdth) {
        if (!(document.documentElement.clientWidth === 0)) {
          winWidth = document.documentElement.clientWidth
        } else {
          winWidth = document.body.clientWidth
        }
      } else {
        winWidth = window.innerWidth
      }
      // if (device.isDesktop()) winWidth = '1000px'
      return winWidth
    },
    /**
     * window height 계산 함수
     * @param 없음
     * @return height 현재 환경에서 제공하는 window height
     */
    windowHeight: function () {
      let winHeight = 0
      if (!window.innerHeight) {
        if (!(document.documentElement.clientHeight === 0)) {
          winHeight = document.documentElement.clientHeight
        } else {
          winHeight = document.body.clientHeight
        }
      } else {
        winHeight = window.innerHeight
      }
      return winHeight
    },
    /**
     * 현재 Element에서 제공하는 height 계산 함수
     * @param el 계산할 element
     * @return height 현재 Element에서 제공하는 height 계산 함수
     */
    outerHeightWithMargin: function (el) {
      let height = el.offsetHeight
      const style = getComputedStyle(el)
      const marginTop = parseInt(style.marginTop)

      height += isNaN(marginTop) ? 0 : marginTop

      return height
    },
    /**
     * 현재 Element에서 제공하는 width 계산 함수
     * @param el 계산할 element
     * @return height 현재 Element에서 제공하는 width 계산 함수
     */
    outerWidthWithMargin: function (el) {
      let width = el.offsetWidth
      const style = getComputedStyle(el)
      const marginLeft = parseInt(style.marginLeft)

      width += isNaN(marginLeft) ? 0 : marginLeft

      return width
    },
    excelDown(param) {
      // 예시
      // const _params = {
      //   targetUrl: 'ADMIN/C2/C2000M0/list',
      //   mapHeader: 'menuId|메뉴아이디,menuKoNm|메뉴명,url|메뉴URL,scrsc|화면번호',
      //   sheetName: 'excelsheetname',
      //   fileName: 'uploadtest.xls',
      //   headerStartRow: 2,
      //   headerStartCol: 1,
      //   targetParameter: '{ \"currentPage\": 1, \"cntPerPage\": 10000 }'
      // }
      const _apiUrl = '/UPLOAD/excel/download/process'
      const tokenApiUrl = '/GW/C1/jwtToken'
      axios.post(tokenApiUrl, {}, { loadingOff: true }).then(result => {
        const res = result.data
        if (res.result.code === 'SUCCESS') {
          const _params = {
            ...param,
            xJwtAuthToken: res.data
          }
          axios.post(_apiUrl, _params, { responseType: 'blob' }).then(result2 => {
            if (result2.data.size) {
              console.log('result.data', result2.headers['content-disposition'].split('=')[1], result2.data)
              const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
              const matches = filenameRegex.exec(result2.headers['content-disposition'])
              const fileName = matches[1].replaceAll('"', '')
              // const res = result.data
              const url = window.URL.createObjectURL(new Blob([result2.data], { type: result2.headers['content-type'] }))
              const link = document.createElement('a')
              link.href = url
              link.setAttribute('download', decodeURI(fileName))
              document.body.appendChild(link)
              link.click()
            } else {
              Vue.$alert('알림', result2.result.clientMessage)
            }
          })
        } else {
          Vue.$alert('알림', res.result.clientMessage)
        }
      })
    },
    /**
    const param = {
      filePathId: 'f46dcf4867264c1aa3b3b81bd7a7e831',
      filePathSeqno: 1
    } */
    imageDown(param) {
      const _apiUrl = '/UPLOAD/file/download/FileForJson'
      const tokenApiUrl = '/GW/C1/jwtToken'
      axios.post(tokenApiUrl, {}, { loadingOff: true }).then(result => {
        const res = result.data
        if (res.result.code === 'SUCCESS') {
          const _params = {
            ...param
          }
          axios.post(_apiUrl, _params, { responseType: 'blob' }).then(result2 => {
            if (result2.data.size) {
              console.log('result.data', result2.headers['content-disposition'].split('=')[1], result2.data)
              // const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
              // const matches = filenameRegex.exec(result2.headers['content-disposition'])
              // const fileName = matches[1].replaceAll('"', '')
              // const res = result.data
              const url = window.URL.createObjectURL(new Blob([result2.data], { type: result2.headers['content-type'] }))
              return url
              // const link = document.createElement('a')
              // link.href = url
              // link.setAttribute('download', decodeURI(fileName))
              // document.body.appendChild(link)
              // link.click()
            } else {
              Vue.$alert('알림', result2.result.clientMessage)
            }
          })
        } else {
          Vue.$alert('알림', res.result.clientMessage)
        }
      })
    },

    /**
     * 저장한 PDF 내용 새창으로 열기
     * @param filePathId : 파일저장id
     * @param filePathSeqno : 저장 순번
     * @return 새창 URL
     */
    pdfDown(param) {
      return new Promise(function (resolve) {
        const _apiUrl = '/UPLOAD/file/download/FileForJson'
        const _params = {
          ...param
        }
        axios.post(_apiUrl, _params, { responseType: 'arraybuffer' }).then(result2 => {
          if (result2.data.byteLength) {
            const url = window.URL.createObjectURL(new Blob([result2.data], { type: 'application/pdf' }))
            return resolve(url)
          } else {
            resolve(false)
          }
        })

        /*
        const tokenApiUrl = '/GW/C1/jwtToken'
        axios.post(tokenApiUrl, {}, { loadingOff: true }).then(result => {
          const res = result.data
          if (res.result.code === 'SUCCESS') {
            const _params = {
              ...param
            }
            axios.post(_apiUrl, _params, { responseType: 'arraybuffer' }).then(result2 => {
              if (result2.data.byteLength) {
                const url = window.URL.createObjectURL(new Blob([result2.data], { type: 'application/pdf' }))
                return resolve(url)
              } else {
                resolve(false)
              }
            })
          } else {
            resolve(false)
          }
        })
        */
      })
    },

    sampleExcelDown(param) {
      // const _params = {
      //   fileName: ''
      // }
      const _params = {
        ...param
      }
      const _apiUrl = '/UPLOAD/excel/template/download'
      axios.post(_apiUrl, _params, { responseType: 'blob' }).then(result => {
        console.log('[sampleExcelDown]', result)
        if (result.data.size) {
          console.log('result.data', result.headers['content-disposition'].split('=')[1], result.data)
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          const matches = filenameRegex.exec(result.headers['content-disposition'])
          const fileName = matches[1].replaceAll('"', '')
          // const res = result.data
          const url = window.URL.createObjectURL(new Blob([result.data], { type: result.headers['content-type'] }))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', decodeURI(fileName))
          document.body.appendChild(link)
          link.click()
        } else {
          Vue.$alert('알림', result.result.clientMessage)
        }
      })
    },

    /**
     * 현재시간 조회
     * @return 현재 시간 YYYYMMDDhhmmss
     */
    getServerTime() {
      return new Promise(function (resolve) {
        const _apiUrl = '/GW/stoplay/login/getnovasysdate'
        axios.post(_apiUrl, {}).then(result => {
          const res = result.data
          if (res.result.code === 'SUCCESS') {
            const today = res.data
            resolve({ code: true, today: today })
          } else {
            resolve({ code: false })
          }
        })
      })
    }
  }
}

export default Plugins
