<template>
  <v-btn class="channel-profile-item" :class="variant">
    <v-chip v-if="showRanking" size="small" color="gray-darken-4" variant="elevated" class="mb-2">{{ ranking }}위</v-chip>
    <UiUserImg :data="userObj" :img-class="''" :img-type="'finfle'"></UiUserImg>

    <div v-if="variant == 'text'" class="d-flex">
      <div class="chip-group-wrap">
        <UiChips v-if="category.length > 0" :chips-list="categoryList" :items="category" />
        <ul class="count">
          <li>
            <span class="label">팔로워</span>
            <span class="value">
              {{ numberFormat(userObj.followerCnt, 0) }}
            </span>
          </li>
        </ul>
      </div>
      <div>
        <p v-dompurify-html="userObj.nickNm" class="name"></p>
      </div>
    </div>

    <div v-else class="d-flex">
      <UiChips v-if="category.length > 0" :chips-list="categoryList" :items="category" />
      <div>
        <!--
          <p v-if="!showFollowerOnly" v-dompurify-html="userObj.interestsNm" class="topic"></p>
          <p v-if="showFollowerOnly" v-dompurify-html="userObj.interestsNm" class="topic"></p>
        -->
        <p v-dompurify-html="userObj.nickNm" class="name"></p>
        <ul class="count">
          <li v-if="!showFollowerOnly">
            <span class="label">의견</span>
            <span class="value">
              {{ numberFormat(userObj.postCnt, 0) }}
            </span>
          </li>
          <li>
            <span class="label">팔로워</span>
            <span class="value">
              {{ numberFormat(userObj.followerCnt, 0) }}
            </span>
          </li>
          <!--
            <li v-if="showFollowing && mine">
              <span class="label">팔로잉</span>
              <span class="value">
                {{ numberFormat(userObj.followingCnt, 0) }}
              </span>
            </li>
          -->
        </ul>
      </div>
    </div>

    <v-icon v-if="showArrow" class="arr-right"></v-icon>
  </v-btn>
</template>
<script>
import { UiUserImg } from '$$bizcomponents'
import { isUndefined, find, isEmpty } from 'lodash'
import UiChips from './UiChips.vue'
import Common from '$$utils/common'
export default {
  name: 'UiChannelProfileItem', //'ChannelProfileItem',
  components: { UiUserImg, UiChips },
  props: {
    item: {
      type: Object
    },
    ranking: {
      type: Number,
      default: 0
    },
    variant: {
      type: String
    },
    showArrow: {
      type: Boolean,
      default: false
    },
    showRanking: {
      type: Boolean,
      default: false
    },
    showChipBtn: {
      type: Boolean,
      default: false
    },
    showFinfleCard: {
      type: Boolean,
      default: true
    },
    showFollowerOnly: {
      // 추가된 prop
      type: Boolean,
      default: false
    },
    showRankingOnly: {
      // 추가된 prop
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      userObj: {},
      category: [],
      categoryList: []
    }
  },
  computed: {},
  watch: {},
  mounted() {
    // console.log('ChannelProfieItem init!!!', this.item)
    let userCardCode = this.$pinia.auth.getCommonCode('ST0044') // 사용자 카드 코드
    let categoryCode = this.$pinia.auth.getCommonCode('ST0042') // 핀플게시판 코드
    this.categoryList = [...categoryCode, ...userCardCode]

    this.userObj = this.item
    if (this.showFinfleCard && !isEmpty(this.userObj.registerDefaultLevel) && this.userObj.registerDefaultLevel == '3') {
      const userCard = find(userCardCode, { value: 'FIN' })
      this.category.push(userCard.value)
    }
    this.category.splice(0, 0, this.userObj.chnlTopicCd)
  },
  methods: {
    numberFormat(value, dotNum) {
      if (isUndefined(value)) return ''
      else {
        let dotFNum = isUndefined(dotNum) ? 0 : dotNum
        return Common.numberFormat(value, dotFNum)
      }
    }
  }
}
</script>
