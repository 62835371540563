<template>
  <v-bottom-sheet
    v-model="sheet"
    class="ui-bottom"
    :transition="smAndUp ? 'fade-transition' : 'bottom-sheet-transition'"
    persistent
    no-click-animation
  >
    <v-card title="기간조회" class="pa-0 text-left">
      <!-- 닫기버튼 -->
      <div class="close-btn" @click="close()"></div>
      <!-- 닫기버튼 -->

      <div class="comtent-btn date-select">
        <button :class="isSelectOneM" @click="monthClick(1)">1개월</button>
        <button :class="isSelectTreM" @click="monthClick(3)">3개월</button>
        <button :class="isSelectFreM" @click="monthClick(0)">직접입력</button>
      </div>
      <v-row cols="12" class="date-calendar">
        <v-col cols="5.5">
          <v-date-input
            v-model="startDate"
            :allowed-dates="startAllowedDates"
            :disabled="disabled"
            hide-actions
            prepend-icon=""
            prepend-inner-icon="$calendar"
            variant="solo"
            @update:model-value="startModelPrint()"
          ></v-date-input>
        </v-col>
        ~
        <v-col cols="5.5">
          <v-date-input
            v-model="endDate"
            :allowed-dates="endAllowedDates"
            :disabled="disabled"
            hide-actions
            prepend-icon=""
            prepend-inner-icon="$calendar"
            variant="solo"
          ></v-date-input>
        </v-col>
      </v-row>
      <!-- 날짜 입력안될시 나타나는 에러메세지 -->
      <!-- <div class="v-input__details"> -->
      <!-- <div id="input-messages" class="v-messages text-left mt-1">일자를 정확하게 입력해주세요.</div> -->
      <!-- </div> -->
      <!-- 날짜 입력안될시 나타나는 에러메세지 -->
      <slot></slot>
      <div class="wrap-c-btn">
        <button class="c-btn c-btn-purple" @click="inqClose()">적용하기</button>
      </div>
    </v-card>
  </v-bottom-sheet>
</template>
<script>
import { useDisplay } from 'vuetify'
export default {
  name: 'UiDateInput',
  components: {},
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    startDt: {
      type: String,
      default: null
    },
    endDt: {
      type: String,
      default: null
    }
  },
  emits: ['close', 'complete', 'update:model-value'],
  setup() {
    const { smAndUp } = useDisplay()
    return {
      smAndUp
    }
  },
  data() {
    return {
      sheet: this.show,
      isSelectOneM: 'on',
      isSelectTreM: '',
      isSelectFreM: '',
      startDate: '',
      endDate: '',
      disabled: true
    }
  },
  watch: {
    modelValue(val) {
      this.sheet = val
    },
    sheet(val) {
      this.$emit('update:model-value', val)
    }
  },
  mounted() {
    this.isSelectOneM = 'on'
    this.startDate = this.setStartDate(1) // this.$vuetify.date.addMonths(new Date(), -1)
    this.endDate = new Date()
    this.disabled = true
  },
  methods: {
    monthClick(selItem) {
      if (selItem === 1) {
        ;(this.isSelectOneM = 'on'), (this.isSelectTreM = ''), (this.isSelectFreM = ''), (this.startDate = this.setStartDate(selItem))
        // (this.startDate = this.$vuetify.date.addMonths(new Date(), -1))
        this.endDate = new Date()
        this.disabled = true
      } else if (selItem === 3) {
        ;(this.isSelectOneM = ''), (this.isSelectTreM = 'on'), (this.isSelectFreM = ''), (this.startDate = this.setStartDate(selItem))
        // (this.startDate = this.$vuetify.date.addMonths(new Date(), -3))
        this.endDate = new Date()
        this.disabled = true
      } else {
        ;(this.isSelectOneM = ''), (this.isSelectTreM = ''), (this.isSelectFreM = 'on'), (this.isSelectOneM = '')
        this.disabled = false
      }
    },
    setStartDate(diff) {
      const endDt = new Date()
      const startDt = new Date()
      startDt.setMonth(startDt.getMonth() - diff)
      if (diff === 1 && endDt.getMonth() === startDt.getMonth()) {
        startDt.setDate(1)
      } else if (diff === 3 && startDt.getDate() !== endDt.getDate()) {
        startDt.setDate(1)
      } else {
        startDt.setDate(startDt.getDate() + 1)
      }
      return startDt
    },
    startModelPrint() {
      console.log('startDate', this.startDate)
    },
    close() {
      this.sheet = false
      this.$emit('close')
    },
    startAllowedDates(val) {
      const toDayChk = this.$vuetify.date.toISO(val) <= this.$vuetify.date.toISO(new Date())
      let beteenChk = true
      if (this.endDate !== '') {
        beteenChk = this.$vuetify.date.toISO(val) > this.$vuetify.date.toISO(this.$vuetify.date.addMonths(this.endDate, -12))
      }
      return toDayChk && beteenChk
    },
    endAllowedDates(val) {
      const toDayChk = this.$vuetify.date.toISO(val) <= this.$vuetify.date.toISO(new Date())
      let beteenChk = true
      if (this.startDate !== '') {
        beteenChk = this.$vuetify.date.toISO(val) <= this.$vuetify.date.toISO(this.$vuetify.date.addMonths(this.startDate, 12))
      }
      return toDayChk && beteenChk
    },
    inqClose() {
      const result = {
        startDate: this.$vuetify.date.toISO(this.startDate),
        endDate: this.$vuetify.date.toISO(this.endDate)
      }
      this.sheet = false
      this.$emit('complete', result)
    }
  }
}
</script>
