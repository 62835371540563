<template>
  <v-btn v-if="quickShow" class="topscroll" @click="goTop()">
    <v-icon dark> mdi-arrow-up</v-icon>
  </v-btn>
</template>
<script>
import { isEmpty } from 'lodash'
export default {
  name: 'QuickMenu',
  components: {},
  props: {},
  setup() {},
  data() {
    return {
      targetElement: null,
      parentWidth: 200,
      windowTop: 0
    }
  },
  computed: {
    quickShow() {
      if (this.windowTop === 0) return false
      else return true
    }
  },
  watch: {},
  mounted() {
    window.addEventListener('scroll', this.onScroll) // TOP일때 quickMenu 제거

    const targetEl = document.querySelector('#quickMenuButton > .v-fab__container')
    if (targetEl) {
      this.targetElement = targetEl
    } else {
      this.targetElement = 'cursor'
    }
    console.log('targetEl', targetEl)
  },
  unmounted() {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    onScroll() {
      this.windowTop = window.top.scrollY
    },
    goTop() {
      const moveItem = document.getElementsByClassName('v-layout')
      if (!isEmpty(moveItem)) {
        moveItem[0].scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    }
  }
}
</script>
