<template>
  <div class="chip-group category">
    <template v-for="cate in category" :key="cate">
      <v-chip size="small" :color="getColor(cate)">{{ getCategoryNm(cate) }}</v-chip>
    </template>
    <!-- <v-chip v-if="finfluencer" color="purple">핀플</v-chip> -->
  </div>
</template>
<script>
import { isEmpty, find } from 'lodash'
export default {
  name: 'UiChips', // 'CategoryChips',
  props: {
    chipsList: {
      type: Object,
      required: true
    },
    items: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      category: []
    }
  },
  watch: {
    items: {
      handler: function (nVal) {
        console.log('[UiChips!!!!] this.category', nVal)
        this.category = nVal
      },
      deep: true
    }
  },
  mounted() {
    this.category = this.items
  },
  methods: {
    getColor(id) {
      const findObj = find(this.chipsList, { value: id })
      return isEmpty(findObj) ? '' : findObj.note
    },
    getCategoryNm(id) {
      const findObj = find(this.chipsList, { value: id })
      return isEmpty(findObj) ? '' : findObj.text
    }
  }
}
</script>
