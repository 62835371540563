<template>
  <div ref="showRef" class="show-screen">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'UiShowScreen',
  props: {
    setTime: {
      type: Number,
      default: 0 // 기본값 설정
    }
  },
  data() {
    return {
      showRef: null,
      timeoutId: null
    }
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll)
    setTimeout(() => {
      this.checkVisibility() // 초기 로드 시에도 체크
    }, 1000)
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    checkVisibility() {
      const elem = this.$refs.showRef
      if (!elem) return
      const rect = elem.getBoundingClientRect()
      const isVisible = rect.top < window.innerHeight * 0.7
      if (isVisible) {
        elem.classList.add('on')
        if (this.setTime > 0) {
          clearTimeout(this.timeoutId)
          this.timeoutId = setTimeout(() => {
            elem.classList.remove('on')
          }, this.setTime)
        }
      } else {
        // elem.classList.remove("on");
      }
    },
    onScroll() {
      this.checkVisibility()
    }
  }
}
</script>
