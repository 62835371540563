<template>
  <div class="tooltip-wrap">
    <v-btn size="small" icon @click="showTooltip = !showTooltip">
      <v-icon class="info"></v-icon>
    </v-btn>
    <div class="tooltip-item" :class="[{ active: showTooltip }, location]">
      <div class="docu">
        {{ text }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'UiTooltip', // 'TooltipWrap',
  props: {
    text: {
      type: String
    },
    location: {
      type: String,
      default: 'left'
    }
  },
  data() {
    return {
      showTooltip: false
    }
  }
}
</script>
