<template>
  <div class="event-landing-wrap" :class="{ responsive: responsive }">
    <div v-for="(item, index) in items" :key="index" class="event-item" :class="'event-nth-0' + (index + 1)">
      <div class="event-cont">
        <UiShowScreen>
          <div class="docu">
            <p v-dompurify-html="item.title" class="title"></p>
            <p v-if="item.subTitle[0]" v-dompurify-html="item.subTitle[0]" class="sub-title nth-01"></p>
            <p v-if="item.subTitle[1]" v-dompurify-html="item.subTitle[1]" class="sub-title nth-02"></p>
            <button v-if="item.btnText" type="button" @click="btnMove(item.btnPath)">{{ item.btnText }}</button>
            <div v-else-if="isAppYn" class="btn-wrap">
              <button type="button" @click="downLink(appDownLink.ios)">
                <img src="/assets/events/2024/12/btn_app_store.png" alt="Download on the App Store" />
              </button>
              <button type="button" @click="downLink(appDownLink.android)">
                <img src="/assets/events/2024/12/btn_google_play.png" alt="Get it on Google Play" />
              </button>
            </div>
          </div>
        </UiShowScreen>
        <UiShowScreen>
          <img class="img" :src="item.imgUrl" />
        </UiShowScreen>
      </div>
    </div>

    <!--
    <div class="wrap-notice bg-surface">
      <p class="tit-notice">꼭 알아두세요</p>
      <ul class="notice-list">
        <li>본 이벤트는 하나증권의 사정에 따라 변경 또는 중단될 수 있습니다. (단, 기 조건 충족손님 제외)</li>
        <li>본 이벤트는 개인정보 수집∙이용, 개인정보 위탁 및 제3자 제공에 동의한 고객을 대상으로 진행됩니다.</li>
        <li>
          두부분식 예비단골 손님 등급부터 참여할 수 있습니다.
          <ul>
            <li>- 예비단골 손님이란? 프로필 정보를 모두 입력한 회원</li>
            <li class="no-bullet">마이페이지 > 프로필 편집에서 프로필 정보를 입력하시면 두부포인트(200모)가 지급됩니다.</li>
          </ul>
        </li>
        <li>본 이벤트 참여는 만 19세 이상 내국인 성인에 한하여 가능하며 법인, 외국인은 이벤트 대상이 아닙니다.</li>
        <li>회원탈퇴 시에는 당첨이 취소되며, 경품이 지급되지 않습니다.</li>
        <li>이용약관에 따라 서비스의 제한이 발생하는 회원의 경우 랭킹 순위 및 경품 지급 대상에서 제외됩니다.</li>
        <li>
          당첨자의 연락 여부 및 탈퇴 등의 사유에 따라 당첨 취소자가 발생할 수 있으며, 이 경우 게시된 경품과 실제 제공되는 경품이 달라질 수 있습니다.
          (상위 순위의 경품이 제공 될 수 있음)
        </li>
        <li>경품 발송을 위한 목적으로 위탁업체인 PlanS에 이벤트 신청손님의 개인정보(이름, 전화번호, 주소)가 제공되며 제공목적 달성 후 폐기됩니다.</li>
        <li>
          경품 증정 시 발송위탁을 거부하거나 수취인 부재, 휴대폰 번호 불명 등의 사유가 5회 이상 발생할 시 이벤트 당첨 및 경품 증정이 취소될 수
          있습니다.
        </li>
        <li>당첨자 선정 및 경품 지급 등은 ㈜INFCL에서 주관하여 진행합니다.</li>
        <li>기타 자세한 사항은 두부분식 모바일 웹 내 1:1 문의 또는 하나증권 손님케어센터(1588-3111)로 문의하시기 바랍니다.</li>
      </ul>
    </div>
    -->
  </div>
</template>
<script>
import { find, isEmpty } from 'lodash'
import { UiShowScreen } from '$$bizcomponents'
export default {
  name: 'UiEventLanding', // 'EventLanding',
  components: {
    UiShowScreen
  },
  props: {
    responsive: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      items: [
        {
          title: `다양한 자산,
                  하나의 커뮤니티
                  쉽게 만나는 투자 정보
                  두부분식`,
          subTitle: [
            null,
            `두부분식에서 투자 정보를 쉽게 만나 보세요
            내가 원하는 투자자산이 하나로 다 모였다!`
          ],
          imgUrl: '/assets/events/2024/12/event_landing_01.png'
        },
        {
          title: `오피니언 리더들의
                  투자 인사이트,
                  핀플채널 하나로
                  충분합니다.`,
          subTitle: [
            `투자가 궁금해? 핀플에게 물어봐!`,
            `핀플채널 커뮤니티에서 자산 별 
            오피니언 리더들의 인사이트를 확인하세요!`
          ],
          btnText: '핀플채널 바로가기',
          btnPath: '/CO/CO0101M01/ALL',
          imgUrl: '/assets/events/2024/12/event_landing_02.png'
        },
        {
          title: `투자자들의 진짜 생각을
                  나누는 공간 오픈톡 하나로
                  충분합니다.`,
          subTitle: [
            '내가 궁금해하던 모든 것, 오픈톡에 다 있네!',
            `투자에 관심있는 누구나 오픈톡 커뮤니티에서
            투자 아이디어를 찾아보세요!`
          ],
          btnText: '오픈톡 바로가기',
          btnPath: '/CO/CO0102M01',
          imgUrl: '/assets/events/2024/12/event_landing_03.png'
        }
      ],
      isAppYn: false,
      appDownLink: {
        android: '',
        ios: ''
      }
    }
  },
  mounted() {
    // 앱 오픈 여부
    const openYnList = this.$pinia.auth.getCommonCode('ST0062') // 오픈여부코드
    const appOpenObj = find(openYnList, { value: 'APP' })
    if (appOpenObj) this.isAppYn = appOpenObj.text === 'Y'
    // 앱 다운로드 링크
    const appDownLinkList = this.$pinia.auth.getCommonCode('ST0063') // 다운로드링크
    const androidLink = find(appDownLinkList, { value: 'ANDROID' })
    const iosLink = find(appDownLinkList, { value: 'IOS' })
    if (androidLink && iosLink) {
      this.appDownLink.android = androidLink.text
      this.appDownLink.ios = iosLink.text
    }
  },
  methods: {
    btnMove(pathStr) {
      this.$router.push({ path: pathStr })
    },
    downLink(link) {
      if (!isEmpty(link)) {
        this.$nativeApi.openWindow(link, '_blank')
      }
    }
  }
}
</script>
