<template>
  <div class="list-top-wrap" :class="className">
    <div class="d-flex">
      <v-checkbox
        v-for="(item, idx) in searchTypeList"
        :key="item.value"
        v-model="checkType[idx]"
        class="small mr-2"
        :label="item.detail"
        hide-details="auto"
        @change="clickSearch(idx, 'chg')"
      ></v-checkbox>
    </div>
    <v-btn icon class="ml-auto" @click="searchPost">
      <v-icon class="search-gray"></v-icon>
    </v-btn>
  </div>
</template>
<script>
import { isEmpty, map, find, forEach, findIndex } from 'lodash'

import { reactive } from 'vue'
import { useAuthStore } from '@/store/index'
import { lowerCase } from 'lodash'

export default {
  name: 'UiSearchBar', //'ListTop',
  components: {},
  props: {
    className: {
      type: String,
      default: ''
    },
    srchType: {
      type: String,
      default: ''
    }
  },
  emits: ['selected-item'],
  setup() {
    // 공통코드 조회
    const pinia = useAuthStore()
    let codeList = pinia.getCommonCode('ST0043')
    map(codeList, obj => {
      obj.icon = lowerCase(obj.value)
      // obj.header = idx === 0 ? '최신순' : obj.text.substring(0, 3) + ' 순'
    })

    const searchTypeList = reactive(codeList)
    let checkList = []
    forEach(searchTypeList, (obj, idx) => {
      checkList[idx] = false
    })
    const checkType = reactive(checkList)

    //console.log('checkType!!! init!!!', checkType)
    return {
      searchTypeList,
      checkType
    }
  },
  data() {
    return {
      openYn: false,
      headerTitle: '최신순'
      //checkType: ''
    }
  },
  watch: {
    srchType(nVal) {
      // const srchTypeObj = find(this.searchTypeList, { note: nVal })
      const srchTypeIndex = findIndex(this.searchTypeList, { note: nVal })
      if (!this.checkType[srchTypeIndex > -1 ? srchTypeIndex : 0]) {
        this.checkType[srchTypeIndex > -1 ? srchTypeIndex : 0] = true
        this.clickSearch(srchTypeIndex > -1 ? srchTypeIndex : 0, 'init')
      }
    }
  },
  mounted() {
    if (!isEmpty(this.srchType)) {
      const srchTypeObj = find(this.searchTypeList, { note: this.srchType })
      const srchTypeIndex = findIndex(this.searchTypeList, { note: this.srchType })
      this.checkType[srchTypeIndex > -1 ? srchTypeIndex : 0] = true
      // this.checkType = isEmpty(srchTypeObj) ? 'RECENT' : srchTypeObj.value
      this.headerTitle = isEmpty(srchTypeObj) ? '최신순' : srchTypeObj.detail
    } else {
      // this.feedSerachType = isEmpty(this.searchTypeList) ? 'RECENT' : this.searchTypeList[0].value
      this.headerTitle = isEmpty(this.searchTypeList) ? '최신순' : this.searchTypeList[0].detail
      this.checkType[0] = true
    }
  },
  methods: {
    /*
    open() {
      this.openYn = true
    },
    close() {
      this.openYn = false
    },
    selectedItem(item) {
      this.headerTitle = item.header
      this.openYn = false
      setTimeout(() => {
        this.$emit('selected-item', item)
      }, 100)
    },
    */
    clickSearch(idx, type) {
      console.log('clickSearchType!!!!', idx, this.checkType[idx], this.searchTypeList[idx])
      if (!this.checkType[idx]) this.checkType[idx] = true
      else {
        forEach(this.checkType, (obj, index) => {
          if (idx !== index) this.checkType[index] = false
        })

        if (type === 'chg') {
          setTimeout(() => {
            this.$emit('selected-item', this.searchTypeList[idx])
          }, 100)
        }
      }
    },
    searchPost() {
      this.$router.push({ path: '/CO/CO0101M05' })
    }
  }
}
</script>
