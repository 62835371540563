<template>
  <div class="gnb">
    <button class="btn-main-logo" @click="movePage('/')">
      <img v-if="mdAndDown" class="main-logo-icon" src="/assets/common/logo_icon.svg" alt="두부분식" />
      <img v-else class="main-logo" src="/assets/main/logo.png" alt="두부분식" />
    </button>
    <ul class="wrap-nav">
      <li>
        <button class="item" @click="movePage('/')">
          <img src="/assets/main/nav-home.png" alt="" class="img-item" />
          <span class="item-text" :class="{ 'tooltip-item start': mdAndDown }">홈</span>
        </button>
      </li>
      <li>
        <button class="item" @click="movePage('/CO/CO0100M01')">
          <img src="/assets/main/nav-community.png" alt="" class="img-item" />
          <span class="item-text" :class="{ 'tooltip-item start': mdAndDown }">커뮤니티</span>
          <!-- <div class="menu-tooltip">출시예정</div> -->
        </button>
        <ul>
          <li><button @click="movePage('/CO/CO0101M01/ALL')">핀플채널</button></li>
          <li><button @click="movePage('/CO/CO0102M01')">오픈톡</button></li>
        </ul>
      </li>
      <li>
        <button class="item" @click="movePage('/PR/PR0100M01')">
          <img src="/assets/main/nav-gift.png" alt="" class="img-item" />
          <span class="item-text" :class="{ 'tooltip-item start': mdAndDown }">혜택</span>
        </button>
      </li>
      <li>
        <button class="item" @click="movePage('/PD/PD0101M01')">
          <img src="/assets/main/nav-lanking.png" alt="" class="img-item" />
          <span class="item-text" :class="{ 'tooltip-item start': mdAndDown }">랭킹</span>
        </button>
      </li>
      <!-- <li>
        <button class="item">
          <img src="/assets/main/nav-card.png" alt="" class="img-item">
          <span class="item-text">카드</span>
        </button>
      </li> -->
    </ul>
    <div class="user-wrap">
      <button aria-label="프로필" @click="moveLogin()">
        <UiUserImg v-if="isAuth" :data="userData"></UiUserImg>
        <v-img v-else class="my-page"></v-img>
        <span class="tooltip-item" :class="[mdAndDown ? 'start' : 'center']">프로필</span>
      </button>
      <button :class="{ active: isAlarm }" aria-label="알림" @click="movePage('/NO/NO0101M01')">
        <v-icon class="alarm"></v-icon>
        <span class="tooltip-item" :class="[mdAndDown ? 'start' : 'center']">알림</span>
      </button>
      <button v-if="isAuth" aria-label="활동로그" @click="moveActivityLog">
        <v-icon class="log-white"></v-icon>
        <span class="tooltip-item" :class="[mdAndDown ? 'start' : 'center']">활동로그</span>
      </button>
    </div>
    <div v-if="isAppYn" class="qr-wrap">
      <p class="text">
        앱에서 더 편리하게<br />
        혜택과 정보를 받아보세요
      </p>
      <v-img :src="qrCodeSrc" />
    </div>
  </div>
</template>
<script>
import { UiUserImg } from '$$bizcomponents'
import { useDisplay } from 'vuetify'
import { isEmpty, find } from 'lodash'

// import { toDataURL } from 'qrcode'
export default {
  name: 'GNB',
  components: { UiUserImg },
  setup() {
    const { mdAndDown } = useDisplay()
    return {
      mdAndDown
    }
  },
  data() {
    return {
      isAppYn: false,
      qrCodeSrc: '/assets/common/img_qr_code.svg'
    }
  },
  computed: {
    isAuth() {
      return this.$pinia.auth.isAuth
    },
    userData() {
      return this.$pinia.auth.userData
    },
    isAlarm() {
      // 알림 존재 여부
      return this.$pinia.dom.isAlarm
    },
    domainMode() {
      return import.meta.env.VUE_APP_BUILD_MODE
    }
  },
  watch: {
    $route: {
      handler: async function (nVal) {
        console.log('[alarmInfo] watch', nVal)
        if (this.isAuth) {
          // 알림정보 재조회
          await this.$pinia.dom.ACTIVE_ALARM()
        } else if (!this.isAuth && this.isAlarm) {
          this.$pinia.dom.isAlarm = false
        }
      },
      deep: true
    }
  },
  mounted() {
    if (this.isAuth) {
      this.$pinia.dom.ACTIVE_ALARM()
    }
    const openYnList = this.$pinia.auth.getCommonCode('ST0062') // 오픈여부코드
    const appOpenObj = find(openYnList, { value: 'APP' })
    if (appOpenObj) this.isAppYn = appOpenObj.text === 'Y'

    switch (this.domainMode) {
      case 'PRODUCTION':
        this.qrCodeSrc = '/assets/common/img_qr_code.svg'
        break
      case 'STAGING':
        this.qrCodeSrc = '/assets/common/img_qr_code_u.svg'
        break
      /*
      case 'DEVELOPMENT':
        this.qrCodeSrc = '/assets/common/img_qr_code_d.svg'
        break
      case 'LOCAL':
        this.qrCodeSrc = '/assets/common/img_qr_code_d.svg'
        break
      */
      default:
        this.qrCodeSrc = '/assets/common/img_qr_code.svg'
        break
    }
  },
  methods: {
    moveLogin() {
      if (this.isAuth) {
        this.movePage('/MB/MB0200M01')
      } else {
        this.movePage('/CM/CM0100M01')
      }
    },
    movePage(pathStr, nameStr) {
      if (!isEmpty(pathStr)) {
        this.$router.push({ path: pathStr })
      } else if (!isEmpty(nameStr)) {
        this.$router.push({ name: nameStr })
      }
    },
    moveActivityLog() {
      // 활동로그로 이동
      this.$router.push({ path: '/CO/CO0104M00', state: { userId: this.userData.userId } })
    }
  }
}
</script>
