<template>
  <v-app-bar class="header">
    <div class="header-menu-left">
      <p class="menu-tit">{{ menuName }}</p>
    </div>

    <div class="header-menu-right"></div>
  </v-app-bar>
</template>
<script>
export default {
  name: 'ThermsHeader',
  components: {},
  data() {
    return {
      tabs: 'one'
    }
  },
  computed: {
    isMobile() {
      return this.$pinia.popup.mode === 'mobile'
    },
    headerName() {
      if (this.isMobile) {
        return '두부분식'
      } else {
        return '세상에 이런 폰트가 나오다니 천재인듯'
      }
    },
    menuName() {
      let pageInfo = this.$router.currentRoute.value.meta
      return pageInfo.title
    }
  },
  mounted() {},
  methods: {}
}
</script>

<style lang="scss"></style>
