import DeviceUtil from '$$utils/device'
import { isUndefined } from 'lodash'
const NativeInterfacePlugin = {
  install: function (Vue) {
    window.openVueWindow = function (url) {
      Vue.$nativeApi.openWindow(url)
    }
    Vue.$nativeApi = Vue.config.globalProperties.$nativeApi = {
      callNativeApi: function (type, cmd, param) {
        window.navigationBack = function () {
          if (window.history.state.back === null && window.history.state.current === '/') {
            console.log('navigationBack', window.history, { close: true, back: false })
            return { close: true, back: false }
          } else {
            console.log('navigationBack', window.history, { close: false, back: true })
            return { close: false, back: true }
          }
        }
        return new Promise(function (resolve) {
          const message = {
            command_type: type,
            command: cmd,
            parameters: param === 'N/A' ? { none: 'N/A' } : param
          }
          // 싱글 콜백 방식
          // window.nativeCallbackFn = function (result) {
          //  resolve(result)
          // }
          // 멀티 콜백 방식
          // 멀티 콜백은 던진 [명령어 + 'NativeCallbackFn'으로 콜하여 각각 콜백함수가 다르게 한다
          window[cmd + 'NativeCallbackFn'] = function (result) {
            resolve(result)
          }

          if (window.appGate) {
            // Call Android interface
            window.appGate.callNativeApi(JSON.stringify(message))
          } else if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.appGate) {
            // Call iOS interface
            window.webkit.messageHandlers.appGate.postMessage(message)
          } else {
            // console.log('No native APIs found.')
            resolve('')
            // reject(new Error('No native APIs found.'))
            // throw new Error('No native APIs found.')
          }
        })
      },
      // 앱 버전 받아오기
      getAppVersion: function () {
        return this.callNativeApi('API', 'getAppVersion', 'N/A')
      },
      // Device ID 받아오기.
      getDeviceId: function () {
        return this.callNativeApi('API', 'getDeviceId', 'N/A')
      },
      // 기기 모델 정보.
      getDeviceModel: function () {
        return this.callNativeApi('API', 'getDeviceModel', 'N/A')
      },
      // 기기 OS 정보.
      getDeviceOS: function () {
        return this.callNativeApi('API', 'getDeviceOS', 'N/A')
      },
      // getCurrentLoaction: function () {
      //   // 현 위치 좌표 받아오기
      //   return this.callNativeApi('API', 'getCurrentLoaction', 'N/A')
      // },
      // getCurrentAddress: function () {
      //   // 현 위치 주소 받아오기
      //   return this.callNativeApi('API', 'getCurrentAddress', 'N/A')
      // },
      // setSmartARS: function (setValue) {
      //   // setValue - 'ON' OR 'OFF'
      //   // 스마트 ARS 설정
      //   return this.callNativeApi('API', 'setSmartARS', { set: setValue })
      // },
      // setLocationPermission(setValue) {
      //   // setValue - 'ON' OR 'OFF'
      //   // 위치 정보 접근 권한 설정
      //   return this.callNativeApi('API', 'setLocationPermission', { set: setValue })
      // },
      // setValue - 'ON' OR 'OFF'
      // 카메라/사진 접근 권한 설정
      setCameraPermission(setValue) {
        return this.callNativeApi('API', 'setCameraPermission', { set: setValue })
      },
      // setValue - 'ON' OR 'OFF'
      // Push 알림 수신 설정
      setPushNotice(setValue) {
        return this.callNativeApi('API', 'setPushNotice', { set: setValue })
      },
      // 전화 걸기
      callDial: function (phoneNo) {
        return this.callNativeApi('API', 'callDial', { phoneNo: phoneNo })
      },
      // 카메라 열기
      showCamera: function () {
        return this.callNativeApi('UI', 'ShowCamera', 'N/A')
      },
      // 겔러리 열기
      showGallery: function () {
        return this.callNativeApi('UI', 'ShowGallery', 'N/A')
      },
      // showIDReader: function () {
      //   return this.callNativeApi('UI', 'ShowIDReader', 'N/A')
      // },
      // showVoiceService: function () {
      //   // 음성변환 서비스
      //   return this.callNativeApi('UI', 'ShowVoiceService', 'N/A')
      // },
      // showCertificateManager: function () {
      //   // 인증서 관리
      //   return this.callNativeApi('UI', 'ShowCertificateManager', 'N/A')
      // },
      // showCertificateImport: function (importFrom) {
      //   // 인증서 가저오기
      //   // importFrom - 'IMPORT_PC' : PC, 'IMPORT_SMART_PHONE': smart phone
      //   return this.callNativeApi('UI', 'ShowCertificateImport', { ImportFrom: importFrom })
      // },
      // showCertificateExport: function (exportTo) {
      //   // 인증서 내보내기
      //   // exportTo - 'EXPORT_PC' : PC, 'EXPORT_SMART_PHONE': smart phone
      //   return this.callNativeApi('UI', 'ShowCertificateExport', { ExportTo: exportTo })
      // },
      // showTransKeypad: function (keypadType) {
      //   // 보안 키패트 화면 호출
      //   // keypadType - 'QUERTY': 쿼티 키패드 , 'NUMBER': 숫자 키 패드
      //   return this.callNativeApi('UI', 'ShowTransKeypad', { KeypadType: keypadType })
      // },
      // showChatbot: function () {
      //   return this.callNativeApi('UI', 'ShowChatbot', 'N/A')
      // },
      // requestScraping: function (type) {
      //   // type - 'NICE' 나이스  OR 'SAS' 쿠콘
      //   return this.callNativeApi('API', 'requestScraping', { Type: type })
      // },
      // 공유하기
      showShareList: function (title, text, url) {
        if (!DeviceUtil.isApp()) {
          if (isUndefined(navigator.share)) {
            return false
          } else {
            navigator.share({
              title: title,
              text: text,
              url: url
            })
            return true
          }
        } else {
          return this.callNativeApi('API', 'showShareList', { title: title, text: text, url: url })
        }
      },
      // 외부 브라우저로 열기
      openWindow: function (url, option) {
        const openUrl = url.startsWith('http') || url.startsWith('blob:') ? url : 'http://' + url
        if (!DeviceUtil.isApp()) {
          if (String(option) === 'undefined') {
            console.log('url, option', url, option)
            window.open(openUrl)
          } else {
            window.open(openUrl, option)
          }
        } else {
          return this.callNativeApi('API', 'openWindow', { url: openUrl })
        }
      }
    }
  }
}

export default NativeInterfacePlugin
