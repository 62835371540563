import { IndexPage, ComDetailLayoutComponets } from '@/views/layout'
const routes = [
  {
    path: 'NO',
    component: IndexPage,
    children: [
      {
        path: 'NO0101M01',
        name: 'NO0101M01',
        components: Object.assign({ content: () => import(/* webpackChunkName: "play_MB" */ '@/views/NO/NO0101M01.vue') }, ComDetailLayoutComponets),
        meta: { requiresAuth: true, title: '알림', description: '알림' }
      }
    ]
  }
]
export default routes
