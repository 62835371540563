<template>
  <div class="wrap-nodata" :class="`size-${size}`">
    <img v-if="!noReportImg" class="icon-nodata" src="/assets/main/nodata.png" alt="데이터 없음" />
    <img v-if="noReportImg" class="icon-nodata" src="/assets/main/noreport.png" alt="데이터 없음" />
    <p v-dompurify-html="text" class="nodata-text"></p>
    <button v-if="btnText" class="btn-benefit" @click="clickNodata">
      {{ btnText }}
      <i class="icon-arrow-right"></i>
    </button>
  </div>
</template>
<script>
export default {
  name: 'UiNoData',
  props: {
    text: {
      type: String
    },
    size: {
      type: String
    },
    btnText: {
      type: String
    },
    noReportImg: {
      type: Boolean,
      default: false
    }
  }, // 'NoData',
  emits: ['nodata-click'],
  data() {
    return {}
  },
  methods: {
    clickNodata() {
      this.$emit('nodata-click')
    }
  }
}
</script>
