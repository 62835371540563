<template>
  <v-app-bar class="header main-header">
    <button class="btn-main-logo" @click="movePage('/')">
      <img class="main-logo" src="/assets/main/logo.png" alt="두부분식" />
    </button>

    <div class="header-menu-right">
      <!-- 로그인 프로필 이미지, 비로그인 아이콘 -->
      <button class="my-profile" title="프로필" @click="moveLogin()">
        <UiUserImg v-if="isAuth" :key="isAuth" :data="userData"></UiUserImg>
        <button v-else class="my-page"></button>
      </button>
      <button :class="{ active: isAlarm }" aria-label="알림" @click="movePage('/NO/NO0101M01')">
        <v-icon class="alarm"></v-icon>
      </button>
      <button class="btn-menu" @click="activeAsideClick()">메뉴</button>
    </div>
  </v-app-bar>
</template>
<script>
import { isEmpty } from 'lodash'
import { UiUserImg } from '$$bizcomponents'
export default {
  name: 'MainHeader',
  components: { UiUserImg },
  data() {
    return {
      tabs: 'one'
    }
  },
  computed: {
    isMobile() {
      return this.$pinia.popup.mode === 'mobile'
    },
    headerName() {
      if (this.isMobile) {
        return '두부분식'
      } else {
        return '세상에 이런 폰트가 나오다니 천재인듯'
      }
    },
    menuName() {
      let pageInfo = this.$router.currentRoute.value.meta
      return pageInfo.title
    },
    isAuth() {
      let isAuth = this.$pinia.auth.isAuth
      return isAuth
    },
    iconClass() {
      if (this.isAuth) return 'on'
      else return ''
    },
    userData() {
      return this.$pinia.auth.userData
    },
    isAlarm() {
      // 알림 존재 여부
      return this.$pinia.dom.isAlarm
    }
  },
  watch: {},
  mounted() {},
  methods: {
    activeAsideClick() {
      this.$pinia.popup.asideActive = !this.$pinia.popup.asideActive
    },
    moveLogin() {
      if (this.isAuth) {
        this.movePage('/MB/MB0200M01')
      } else {
        this.movePage('/CM/CM0100M01')
      }
    },
    movePage(path, name) {
      if (!isEmpty(path)) {
        this.$router.push({ path: path })
      } else {
        this.$router.push({ name: name, query: {}, state: {} })
      }
    },
    goBack() {
      let history = ''
      try {
        history = this.$router.options.history.state.back
      } catch (error) {
        this.movePage('/')
      }
      if (isEmpty(history)) {
        this.movePage('/')
      } else {
        this.$router.go(-1)
      }
    }
  }
}
</script>

<style lang="scss"></style>
