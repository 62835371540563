import Header from './Header.vue'
import SettingHeader from './SettingHeader.vue'
import SimpleHeader from './SimpleHeader.vue'
import MainHeader from './MainHeader.vue'
import ErrorHeader from './ErrorHeader.vue'
import ThermsHeader from './ThermsHeader.vue'
import Footer from './Footer.vue'
import IndexPage from './IndexPage.vue'
import SideMenu from './SideMenu.vue'
import Quick from './QuickMenu.vue'
import TabNav from './TabNav.vue'
import FooterNav from './FooterNav.vue'
import AbstractRoute from './AbstractRoute.vue'
import IndexContentPage from './IndexContentPage.vue'
// 커뮤니티 레이아웃 st
import ComHeader from './ComHeader.vue'
import ComQuickMenu from './ComQuickMenu.vue'
import OpinionHeader from './OpinionHeader.vue'
import SearchHeader from './SearchHeader.vue'
import BackHeader from './BackHeader.vue'
// 커뮤니티 레이아웃 ed

// 2차 레아아웃 st
import GNB from './GNB.vue'
import SideContent from './SideContent.vue'
// 2차 레아아웃 ed

const defaultLayoutComponets = {
  header: Header,
  gnb: GNB,
  aside: SideMenu,
  quick: Quick,
  footer: Footer,
  tabnav: TabNav,
  footerNav: FooterNav,
  sideContent: SideContent
}

const settingLayoutComponets = {
  header: SettingHeader,
  gnb: GNB,
  aside: SideMenu,
  quick: Quick,
  footer: Footer,
  tabnav: TabNav,
  footerNav: FooterNav,
  sideContent: SideContent
}

const mainLayoutComponets = {
  header: MainHeader,
  gnb: GNB,
  aside: SideMenu,
  quick: Quick,
  footer: Footer,
  tabnav: TabNav,
  footerNav: FooterNav,
  sideContent: SideContent
}

const simpleLayoutComponets = {
  header: SimpleHeader,
  gnb: GNB,
  aside: SideMenu,
  // quick: Quick,
  footer: Footer,
  tabnav: TabNav,
  footerNav: FooterNav,
  sideContent: SideContent
}

const ErrorLayoutComponets = {
  header: ErrorHeader
  // aside: SideMenu,
  // quick: Quick,
  // footer: Footer,
  // tabnav: TabNav,
  // footerNav: FooterNav
}

const ThermsLayoutComponets = {
  header: ThermsHeader,
  gnb: GNB,
  sideContent: SideContent
}

const CommunityDefaultLayoutComponets = {
  header: ComHeader,
  gnb: GNB,
  aside: SideMenu,
  quick: ComQuickMenu,
  sideContent: SideContent
}

const ComWriteLayoutComponets = {
  header: OpinionHeader,
  gnb: GNB,
  sideContent: SideContent
}

const ComDetailLayoutComponets = {
  header: BackHeader,
  gnb: GNB,
  sideContent: SideContent
}

const backLayoutComponets = {
  header: BackHeader,
  gnb: GNB,
  footer: Footer,
  sideContent: SideContent
}

const ComSearchLayoutComponets = {
  header: SearchHeader,
  gnb: GNB,
  quick: ComQuickMenu,
  sideContent: SideContent
}

const ComMainLayoutComponets = {
  header: Header,
  gnb: GNB,
  aside: SideMenu,
  quick: ComQuickMenu,
  footer: Footer,
  footerNav: FooterNav,
  sideContent: SideContent
}

const rendingLayoutComponents = {
  footer: Footer
}
export {
  Header,
  SettingHeader,
  MainHeader,
  ErrorHeader,
  ThermsHeader,
  Footer,
  SideMenu,
  Quick,
  TabNav,
  FooterNav,
  IndexPage,
  AbstractRoute,
  IndexContentPage,
  defaultLayoutComponets,
  settingLayoutComponets,
  mainLayoutComponets,
  simpleLayoutComponets,
  ErrorLayoutComponets,
  ThermsLayoutComponets,
  // 커뮤니티 레이아웃
  ComHeader,
  ComQuickMenu,
  OpinionHeader,
  BackHeader,
  SearchHeader,
  CommunityDefaultLayoutComponets,
  ComWriteLayoutComponets,
  ComDetailLayoutComponets,
  ComSearchLayoutComponets,
  ComMainLayoutComponets,
  backLayoutComponets,
  // 2차 레이아웃
  GNB,
  SideContent,
  rendingLayoutComponents
}
