<template>
  <v-card class="feed-item" :class="{ 'has-battle': !isFinfle && feedObj.surveyId }">
    <template v-if="feedObj.reportStatusCd">
      <div class="d-flex mb-4">
        <v-chip v-if="feedObj.reportStatusCd == '01'" color="blue-light" variant="text">신고 접수</v-chip>
        <v-chip v-if="feedObj.reportStatusCd == '02'" color="pink-light" variant="text">신고 삭제</v-chip>
      </div>
    </template>

    <div class="user-wrap cursor-pointer" @click="moveUserActivity(item.regId)">
      <div class="user-img-wrap">
        <UiUserImg v-if="!adminYn" :data="feedObj" :img-class="'user-img'" :img-type="'finfle'"></UiUserImg>
        <v-img v-else class="user-img" :src="imgUrl" cover></v-img>
      </div>

      <div class="user-info">
        <div class="user-name">
          <p class="name">
            {{ name }}
          </p>
          <template v-for="(card, idx) in userCard" :key="idx">
            <v-chip size="small" :color="getColor(card)">{{ getCategoryNm(card) }}</v-chip>
          </template>
        </div>
        <ul class="user-type">
          <li v-if="isFinfle" class="user-channel">{{ feedObj.baseNickNm }} 채널</li>
          <li class="user-time">
            {{ feedObj.regDtMark }}
          </li>
        </ul>
      </div>
    </div>
    <div v-if="feedObj.reportStatusCd && feedObj.reportStatusCd == '02'" class="delete-message-wrap">
      <p class="title">아래 가이드라인 위반으로 삭제되었습니다.</p>
      <p class="docu">삭제 이유 : {{ feedObj.reportRsnContents }}</p>
    </div>
    <div v-else class="docu-wrap cursor-pointer" @click="moveFeed('contents')">
      <div class="flex-fill">
        <div v-if="isFinfle && feedObj.title" class="title">
          <!-- TODO 확인 필요 : 핀플에는 하이라이트 없음
            <template v-if="item.icons">
              <v-icon v-for="icon in item.icons" :key="icon" class="docu-icon" :class="icon"></v-icon>
            </template>
          -->
          <em v-dompurify-html="feedObj.title"></em>
        </div>

        <!-- { 'ellipsis-1': item.chips },  -->
        <div class="docu" :class="[{ 'ellipsis-2': isFinfle && feedObj.title }]">
          <template v-if="highlightCd">
            <v-icon v-for="hight in highlightCd" :key="hight.value" class="docu-icon" :class="hight.note"></v-icon>
          </template>
          <em v-dompurify-html="feedObj.contents"></em>
        </div>
        <!-- TODO 게시글 내용 클래스, 해시태그 처리 -->
        <!-- 
        <div v-if="item.chips" class="chip-wrap">
          <v-chip v-for="item in item.chips" :key="item.id" color="gray-darken-3" variant="elevated" size="large"> #{{ item }} </v-chip>
        </div>
        TODO 칩스가 뭐야? : 해시태그처럼 보임 : 삭제 되었음
        -->
        <!-- 해시태그 삭제
        <div v-dompurify-html="feedObj.contents" class="docu" :class="{ 'ellipsis-1': searchYn && feedObj.hashtagList }"></div>
          <div v-if="searchYn && feedObj.hashtagList" class="chip-wrap">
            <v-chip v-for="hashtag in feedObj.hashtagList" :key="hashtag" color="gray-darken-3" variant="elevated" size="large">
              #{{ hashtag }}
            </v-chip>
          </div>
        -->
      </div>
      <v-img v-if="imgSrc" :key="imgSrc" class="docu-img" :src="imgSrc" cover :eager="true"></v-img>
    </div>

    <div v-if="!isFinfle && feedObj.surveyId">
      <UiBattleItem :disabled="showResult" variant="list" :item="feedObj" />
      <!--
       @click="moveFeed('contents')"
      :edit-yn="false"
      -->
    </div>

    <div v-if="!feedObj.reportStatusCd" class="action-wrap">
      <div class="action-btn">
        <v-checkbox
          v-model="likeCheckYn"
          class="like small"
          :label="numberFormat(feedObj.likeCount, 0)"
          hide-details="auto"
          @change="modifyLike('LIKE')"
        ></v-checkbox>
        <v-checkbox
          v-if="dislikeYn"
          v-model="hateCheckYn"
          class="dislike small"
          :label="numberFormat(feedObj.hateCount, 0)"
          hide-details="auto"
          @change="modifyLike('HATE')"
        ></v-checkbox>
        <v-btn variant="text" size="x-small" class="text-light-4" @click="feedObj.commentCount > 0 ? moveFeed('comment') : false">
          <v-icon class="comment"></v-icon>
          {{ numberFormat(feedObj.commentCount, 0) }}
        </v-btn>
      </div>
      <UiChips v-if="category.length > 0" :chips-list="categoryList" :items="category" />
    </div>
  </v-card>
</template>
<script>
import { isUndefined, isEmpty, find, reduce } from 'lodash'
import Common from '$$utils/common'
import { UiUserImg } from '$$bizcomponents'
import UiBattleItem from './UiBattleItem.vue'
import UiChips from './UiChips.vue'

import { reactive } from 'vue'
import { useAuthStore } from '@/store/index'
export default {
  name: 'UiFeedCard', // 'FeedItem',
  components: { UiUserImg, UiBattleItem, UiChips },
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      },
      required: true
    },
    classTxt: {
      type: String,
      default: ''
    },
    moveType: {
      type: String,
      default: 'push'
    },
    searchYn: {
      type: Boolean,
      default: false
    },
    moveEmitYn: {
      type: Boolean,
      default: false
    }
  },
  emits: ['move-feed'],
  setup() {
    // 공통코드 조회
    const pinia = useAuthStore()

    let finfleList = pinia.getCommonCode('ST0042') // 핀플게시판 코드
    let openTalkList = pinia.getCommonCode('ST0031') // 오픈톡 코드
    const userCardCode = reactive(pinia.getCommonCode('ST0044')) // 사용자 카드 색상 코드
    const highlightList = pinia.getCommonCode('ST0046') // 하이라이트 목록

    finfleList = [...finfleList, ...userCardCode]
    const categoryList1 = reactive(finfleList)

    openTalkList = [...openTalkList, ...userCardCode]
    const categoryList2 = reactive(openTalkList)

    return {
      categoryList1,
      categoryList2,
      userCardCode,
      highlightList
    }
  },
  data() {
    return {
      categoryList: [],
      category: [],
      userCard: [],
      highlightCd: [],
      feedObj: {
        likeYn: 'N',
        hateYn: 'N'
      },
      imgSrc: '',
      dislikeYn: false,
      likeCheckYn: false,
      hateCheckYn: false,
      adminYn: false,
      isFinfle: false,
      showResult: false
    }
  },
  computed: {
    isAuth() {
      return this.$pinia.auth.isAuth
    },
    imgUrl() {
      if (this.adminYn) {
        return '/assets/common/character_64_admin.svg'
      } else {
        return ''
      }
    },
    name() {
      if (this.adminYn) {
        return '두부분식매니저'
      } else {
        return this.feedObj.nickNm
      }
    },
    siteType() {
      return this.$pinia.com.siteType
    },
    isReport() {
      return !isUndefined(this.feedObj.reportStatusCd) && this.feedObj.reportStatusCd === '02'
    }
  },
  watch: {},
  mounted() {
    this.feedObj = this.item
    if (!isEmpty(this.feedObj)) {
      this.dataSetting()
    }
  },
  methods: {
    dataSetting() {
      const val = this.feedObj

      if (!isEmpty(val.fileUploadClientList)) {
        this.imgSrc = '/GW' + val.fileUploadClientList[0].fileUrl + '&thumbnailSize=300'
      } else if (!isEmpty(val.movieUrl)) {
        this.imgSrc = '/assets/common/img_empty.svg'
        this.getVideoThumnail(val.movieUrl)
        // this.$forceUpdate()
      }
      // 하이라이트 추가
      if (!isEmpty(val.highlightCd)) {
        const highlight = val.highlightCd.split(',')
        this.highlightCd = reduce(
          highlight,
          (crr, arr) => {
            const findCode = find(this.highlightList, { value: arr })
            if (!isEmpty(findCode)) {
              crr.push(findCode)
            }
            return crr
          },
          []
        )
      }
      // 사용자 카드 노출 추가
      this.category = []
      this.userCard = []
      if (val.registerDefaultLevel === 3) {
        const userCard = find(this.userCardCode, { value: 'FIN' })
        if (!isEmpty(userCard)) {
          this.userCard.push(userCard.value)
        }
      } else if (isEmpty(val.nickNm)) {
        this.adminYn = true
        const userCard = find(this.userCardCode, { value: 'ADMIN' })
        if (!isEmpty(userCard)) {
          this.userCard.push(userCard.value)
        }
      }

      if (val.tableId !== 'FINFLUENCER') {
        this.dislikeYn = true
        this.categoryList = this.categoryList2
        // this.category.push(this.feedObj.postSubjectCd) // 오픈톡 주제
        isEmpty(val.postSubjectCd) ? false : this.category.splice(0, 0, val.postSubjectCd)

        // 오픈톡인 경우 배틀 처리
        this.showResult = !isEmpty(this.feedObj.answer)
      } else {
        // 핀플채널
        this.dislikeYn = false
        this.isFinfle = true
        this.categoryList = this.categoryList1
        isEmpty(val.baseChnlTopicCd) ? false : this.category.splice(0, 0, val.baseChnlTopicCd)
        // this.category.push(this.feedObj.baseChnlTopicCd) // 핀플채널 주제
      }
      // 좋아요/싫어요
      this.likeCheckYn = val.likeYn === 'Y'
      this.hateCheckYn = val.hateYn === 'Y'
    },
    // 유튜브 썸네일 가져오기
    getVideoThumnail(url) {
      const id = this.$novaCom.filteredUrlId(url)
      if (!isUndefined(id)) {
        const youtubeApi = '/GW/stoplay/proxy/send'
        const _params = {
          snsType: 'GGL',
          subUrl: '/youtube/v3/videos?id=' + id + '&key=' + import.meta.env.VUE_APP_GOOGLE_AUTH_JS_KEY + '&part=snippet',
          accessToken: '',
          proxyHeader: '{}',
          proxyBody: '{}',
          method: 'GET'
        }
        console.log('_params', _params)

        this.$http.post(youtubeApi, _params, { loadingOff: true }).then(res => {
          // console.log('youtube', res)
          const result = res.data.items
          // console.log('[movieResult!!!]', result[0].snippet.thumbnails.medium.url)
          if (!isEmpty(result)) {
            this.imgSrc = result[0].snippet.thumbnails.medium.url
          } else {
            console.log('getVideoThumnail proxy error')
          }
        })
      }
    },
    numberFormat(value, dotNum) {
      if (isUndefined(value)) return ''
      else {
        let dotFNum = isUndefined(dotNum) ? 0 : dotNum
        return Common.numberFormat(value, dotFNum)
      }
    },
    moveFeed(type) {
      // 상세페이지 이동
      console.log('[moveFeed init!!!]', type)
      if (!this.isReport) {
        // 상세 페이지로 이동 추가
        // const movePage = this.feedObj.boardType === 'FIN' ? 'CO0101M02' : 'CO0102M02' : 신고된게시글 사용불가로 아래로 변경처리
        const movePage = this.feedObj.tableId === 'FINFLUENCER' ? 'CO0101M02' : 'CO0102M02'
        if (this.moveEmitYn) {
          const result = {
            result: 'move',
            movePage: movePage,
            contentsNo: this.feedObj.contentsNo,
            movePlace: type
          }
          this.$emit('move-feed', result)
        } else {
          this.$router[this.moveType]({
            name: movePage,
            query: { contentsNo: this.feedObj.contentsNo },
            state: { contentsNo: this.feedObj.contentsNo, movePlace: type }
          })
        }
      }
    },
    moveUserActivity(id) {
      // 사용자 활동로그 이동
      // console.log('[moveUserActivity init!!!]', id)
      if (!this.adminYn && !this.isReport) {
        this.$router.push({ path: '/CO/CO0104M00', state: { userId: id } })
      }
    },
    modifyLike(type) {
      // 좋아요/싫어요 처리
      // console.log('modifyLike!!! init', type)
      const typeLower = type.toLowerCase()
      if (this.isAuth && !this.isReport) {
        const isUseYn = this.feedObj[typeLower + 'Yn'] === 'Y' ? 'N' : 'Y'
        const params = {
          list: [
            {
              cmd: 'I',
              tableId: this.feedObj.tableId,
              boardType: this.feedObj.boardType,
              contentsNo: this.feedObj.contentsNo,
              userId: this.$pinia.auth.userData.userId,
              actionTypeCd: type, // 활동구분코드 LIKE HATE
              useYn: isUseYn,
              writeTypeCd: 'POST'
            }
          ]
        }
        const modiLikeApi = '/GW/router/STOPLAY/CMN/CmnMemberPostAction/save'
        this.$http.post(modiLikeApi, params).then(res => {
          const result = res.data
          if (result.result.code === 'SUCCESS') {
            if (this.feedObj[typeLower + 'Yn'] === 'Y') {
              this.feedObj[typeLower + 'Yn'] = 'N'
              this.feedObj[typeLower + 'Count']--
            } else {
              this.feedObj[typeLower + 'Yn'] = 'Y'
              this.feedObj[typeLower + 'Count']++

              // 좋아요/싫어요 하나만 가능하도록 처리
              if (type === 'LIKE' && this.feedObj.hateYn == 'Y') {
                this.feedObj.hateYn = 'N'
                this.feedObj.hateCount--
                this.hateCheckYn = false
              } else if (type === 'HATE' && this.feedObj.likeYn == 'Y') {
                this.feedObj.likeYn = 'N'
                this.feedObj.likeCount--
                this.likeCheckYn = false
              }
            }
          } else {
            this[typeLower + 'CheckYn'] = !this[typeLower + 'CheckYn']
            this.$alert('알림', result.result.clientMessage)
          }
        })
      } else {
        this[typeLower + 'CheckYn'] = false
        this.$confirm('알림', this.$t('message.STC_0004'), '취소', '로그인/회원가입', undefined).then(result => {
          if (result == 'secondResult') {
            this.$router.push({ name: 'CM0100M01' })
          }
        })
      }
    },
    getColor(id) {
      const findObj = find(this.categoryList, { value: id })
      return isEmpty(findObj) ? '' : findObj.note
    },
    getCategoryNm(id) {
      const findObj = find(this.categoryList, { value: id })
      return isEmpty(findObj) ? '' : findObj.text
    }
  }
}
</script>
