<template>
  <v-bottom-sheet
    v-model="isOpenYn"
    class="ui-bottom"
    :transition="smAndUp ? 'fade-transition' : 'bottom-sheet-transition'"
    persistent
    no-click-animation
  >
    <v-card :title="termNm" class="text-left pa-0">
      <div class="close-btn" @click="closeSheet('close')"></div>
      <div v-for="(item, index) in dataSearchParam.dataList" :key="index" class="wrap-scroll">
        <div v-if="displayIndex === index">
          <div class="wrap-terms">
            <div class="terms-item">
              <!-- ToDo: 필수일때 .certain 추가 -->
              <!--
                약관이 각각 하나씩 노출되기 때문에 헤더값 상위로 변경
                <h3 v-if="showDetailTitle" class="term-tit" :class="item.mndtYn === 'Y' ? 'certain' : ''">{{ item.termNm }}</h3>
              -->
              <div v-dompurify-html="item.termContents" class="term-desc"></div>
            </div>
          </div>
        </div>
      </div>
    </v-card>
    <div class="wrap-term-bottom-btn wrap-c-btn">
      <button class="c-btn c-btn-gray bg-light rate-1" @click="closeSheet('reject')">아니요</button>
      <button class="c-btn c-btn-purple bg-light rate-2" @click="closeSheet('confirm')">네, 동의합니다</button>
    </div>
  </v-bottom-sheet>
</template>

<script>
import { isEmpty } from 'lodash'
import { useDisplay } from 'vuetify'

export default {
  name: 'UiBottomTerms',
  mixins: [],
  props: {
    title: {
      type: String,
      default: '이벤트 응모 동의'
    },
    isOpenProps: {
      type: Boolean,
      default: false
    },
    description: {
      type: [String, Array],
      default: ''
    },
    termsGpCd: {
      type: String,
      default: ''
    }
    /*
    showDetailTitle: {
      type: Boolean,
      default: true
    }
    */
  },
  emits: ['btm-close', 'btm-list-save', 'btm-props-change'],
  setup() {
    const { smAndUp } = useDisplay()
    return {
      smAndUp
    }
  },
  data() {
    return {
      dataList: [],
      isOpenYn: false,
      desc: [],
      // 기본 조회 param
      dataSearchParam: {
        // 조회 API
        dataApi: '/GW/router/STOPLAY/CMN/CmnTermsGroupIndividualRltn/listDetail',
        // 기본 목록 조회 params
        defaultParams: {
          currentPage: 1,
          cntPerPage: 20
        },
        // 검색조건 params
        searchParams: {
          termGroupCd: '',
          useYn: 'Y'
        },
        // 검색 데이터
        dataList: []
      },
      dataLength: 0,
      confirmCnt: 0,
      displayIndex: 0
    }
  },
  computed: {
    // console.log('UICommonModal computed')
    termNm() {
      if (isEmpty(this.dataSearchParam.dataList)) return this.title
      else return this.dataSearchParam.dataList[this.displayIndex].termNm
    }
  },
  watch: {
    isOpenProps: {
      handler: function () {
        this.isOpenYn = this.isOpenProps
      },
      deep: true
    },
    isOpenYn() {
      if (this.isOpenProps && !this.isOpenYn) this.closeSheet('close')
    },
    termsGpCd(nVal) {
      this.dataSearchParam.searchParams.termGroupCd = nVal
      this.getTermsList()
    }
  },
  mounted() {
    this.isOpenYn = this.isOpenProps
    this.dataSearchParam.searchParams.termGroupCd = this.termsGpCd

    if (!isEmpty(this.dataSearchParam.searchParams.termGroupCd)) {
      this.getTermsList()
    }
  },
  updated() {
    // console.log('UICommonModal updated')
  },
  unmounted() {
    // console.log('UICommonModal unmounted')
  },
  methods: {
    closeSheet(result) {
      if (result === 'confirm') this.confirmCnt++
      if (result !== 'confirm') {
        this.$emit('btm-close', result)
        this.confirmCnt = 0
        this.displayIndex = 0
      } else {
        if (this.dataLength === this.confirmCnt) {
          this.$emit('btm-close', result)
          this.confirmCnt = 0
          this.displayIndex = 0
        } else {
          this.displayIndex++
        }
      }
    },
    getTermsList() {
      const params = { ...this.dataSearchParam.defaultParams, ...this.dataSearchParam.searchParams }
      // console.log('[getTermsList params!!!]', params)
      this.$http.post(this.dataSearchParam.dataApi, params).then(res => {
        // console.log('[getTermsList result!!!]', res)
        const result = res.data
        if (result.result.code === 'SUCCESS') {
          if (!isEmpty(result.data)) {
            this.dataSearchParam.dataList = result.data
            this.dataLength = this.dataSearchParam.dataList.length
          } else {
            // 데이터 미존재 시
            this.dataSearchParam.dataList = []
          }
        } else {
          this.$alert('알림', result.result.clientMessage)
        }
      })
    }
  }
}
</script>
<style lang="scss"></style>
