<template>
  <v-bottom-sheet
    v-model="isOpenYn"
    class="ui-bottom"
    :transition="smAndUp ? 'fade-transition' : 'bottom-sheet-transition'"
    persistent
    no-click-animation
  >
    <v-checkbox v-if="showDueDate" v-model="isNoneOpen" class="small fixed-top" label="7일간 보지 않기" hide-details="auto"></v-checkbox>
    <v-card class="pa-0 text-left">
      <v-card-item>
        <v-card-title v-dompurify-html="title"> </v-card-title>
      </v-card-item>
      <div v-if="!hideClose" class="close-btn" @click="closeSheet()"></div>
      <div class="wrap-scroll">
        <slot></slot>
      </div>
    </v-card>
    <slot name="footer"> </slot>
  </v-bottom-sheet>
</template>

<script>
import { useDisplay } from 'vuetify'
export default {
  name: 'UiBottom',
  props: {
    title: {
      type: String,
      default: ''
    },
    isOpenProps: {
      type: Boolean,
      default: false
    },
    hideClose: {
      type: Boolean,
      default: false
    },
    showDueDate: {
      type: Boolean,
      default: false
    }
  },
  emits: ['btm-close'],
  setup() {
    const { smAndUp } = useDisplay()
    return {
      smAndUp
    }
  },
  data() {
    return {
      isOpenYn: false,
      isNoneOpen: false
    }
  },
  watch: {
    isOpenProps() {
      this.isOpenYn = this.isOpenProps
    },
    isOpenYn() {
      if (!this.isOpenYn) {
        this.closeSheet()
      }
    }
  },
  mounted() {
    this.isOpenYn = this.isOpenProps
  },
  methods: {
    closeSheet() {
      this.$emit('btm-close', { showDueDate: this.showDueDate, isNoneOpen: this.isNoneOpen })
    }
  }
}
</script>
<style lang="scss"></style>
