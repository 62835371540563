<template>
  <div v-if="multiple">
    <file-upload
      v-for="(uploadFile, index) in uploadFileObj"
      v-show="index === uploadFileObj.length - 1"
      :key="uploadFile.id"
      :ref="uploadFile.id"
      v-model="uploadFile.model"
      v-bind="attributes"
      :input-id="uploadFile.id"
      :name="uploadFile.name"
      :class="btnClass"
      :size="size || 0"
      :multiple="multiple"
      :maximum="multiple ? maxFiles : 1"
      :active="uploadFile.active"
      :destroy="uploadFile.destroy"
      @input-filter="localInputFilter"
      @input-file="localInputFile"
    >
      <slot></slot>
    </file-upload>
  </div>

  <file-upload
    v-else
    v-model="uploadFileObj[0].model"
    :input-id="uploadFileObj[0].id"
    :name="uploadFileObj[0].name"
    :class="btnClass"
    :size="size || 0"
    v-bind="attributes"
    :active="uploadFileObj[0].active"
    :destroy="uploadFileObj[0].destroy"
    @input-filter="localInputFilter"
    @input-file="localInputFile"
  >
    <slot></slot>
  </file-upload>
</template>

<script>
import FileUpload from './vue-upload-component/FileUpload.vue'
import { isEmpty, findIndex, find, reduce, filter, forEach, map } from 'lodash'
import Common from '$$utils/common'
// import defaultPreviewImg from '/assets/img/mo/attach-file.png'

import UiFullSizeImgModal from '$$components/modal/UiFullSizeImgModal.vue'

export default {
  name: 'UiFileUpload',
  components: {
    FileUpload
  },
  props: {
    id: {
      type: String
    },
    name: {
      type: String
    },
    title: {
      type: String
    },
    viewType: {
      type: String,
      default: 'file'
    },
    extensions: {
      type: String,
      default: ''
    },
    subText: {
      type: String,
      default: ''
    },
    btnClass: {
      type: String,
      default: ''
    },
    multiple: {
      type: Boolean,
      default: false
    },
    maxFiles: {
      type: Number,
      default: 2
    },
    autoUpload: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: undefined
    },
    size: {
      type: Number,
      default: 0
    },
    rFileList: {
      type: Array,
      default: () => {
        return []
      }
    },
    uploadKey: {
      type: String,
      default: ''
    },
    uploadUrl: {
      type: String,
      default: '/upload/files'
    },
    modelValue: {
      type: Array,
      default: () => {
        return []
      }
    },
    uploadParams: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  emits: ['rfile-input', 'input-filter', 'input-fail', 'update:modelValue', 'upload_complete', 'upload_fail', 'upload_done'],
  data() {
    return {
      uploadFileObj: [],
      sessionKey: '',
      tokenParams: {},
      deleteFileObj: []
    }
  },
  computed: {
    isMobile() {
      return this.$device.isMobile()
    },
    isDocType() {
      return this.viewType.toLowerCase() === 'file'
    },
    html5UploadSupport() {
      return !!(window.File && window.FormData)
    },
    fileExtensions() {
      return 'gul, txt, hwp, doc, docx, xls, xlsx, ppt, pptx'
    },
    imgExtensions() {
      return 'gif, jpg, jpeg, png, bmp, webp'
    },
    attachListClasses() {
      return {
        'attach-list attach-list__file': this.isDocType,
        'attach-list attach-list__image': !this.isDocType
      }
    },
    attributes() {
      const attrs = {}
      if (!isEmpty(this.extensions)) {
        attrs.extensions = this.extensions
      } else {
        if (this.viewType.toLowerCase() === 'file') {
          attrs.extensions = this.fileExtensions
        } else {
          attrs.extensions = this.imgExtensions
        }
      }

      if (this.isMobile) {
        attrs.accept = 'image/*'
      }

      return attrs
    },
    showFileList() {
      return reduce(
        this.uploadFileObj,
        function (result, value) {
          // , key
          if (!isEmpty(value.model)) {
            result = result.concat(value.model)
          }
          return result
        },
        []
      )
    },
    readFileList() {
      return map(this.rFileList, function (val) {
        val.rfile = true

        return val
      })
    }
  },
  watch: {
    uploadFileObj: {
      handler: function () {
        // nVal
        if (!(this.multiple && !this.html5UploadSupport)) {
          this.emitFileList()
        }
      },
      deep: true
    }
  },
  created() {},
  beforeMount() {
    const ranVal = Common.randomValue(89999) + 10000
    this.getUploadSessionKey()

    if (this.uploadFileObj.length > 0) {
      this.uploadFileObj.splice(0, this.uploadFileObj.length)
    }
    this.uploadFileObj.push({
      id: this.id + '_' + ranVal,
      name: this.name + '_' + ranVal,
      model: [],
      active: true,
      destroy: false
    })
  },
  mounted() {},
  methods: {
    iconName(name) {
      if (/\.(jpe?g|gif|png|bmp|webp)$/i.test(name)) {
        return 'icon-img'
      } else if (/\.xlsx?$/i.test(name)) {
        return 'icon-xls'
      } else if (/\.docx?$/i.test(name)) {
        return 'icon-doc'
      } else if (/\.pptx?$/i.test(name)) {
        return 'icon-ppt'
      } else if (/\.pdf$/i.test(name)) {
        return 'icon-pdf'
      } else if (/\.hwpx?$/i.test(name)) {
        return 'icon-hwp'
      } else if (/\.gul$/i.test(name)) {
        return 'icon-gul'
      } else if (/\.txt$/i.test(name)) {
        return 'icon-txt'
      } else {
        return 'icon-file'
      }
    },
    fileSize(val) {
      return Common.fileSize(val)
    },
    isImageFile(name) {
      if (isEmpty(name)) {
        return false
      }

      if (!/\.(jpe?g|gif|png|bmp|webp)$/i.test(name)) {
        return false
      }

      return true
    },
    removeFile(fileObj) {
      const _self = this

      if (!fileObj.rfile) {
        const findFileIdx = findIndex(_self.uploadFileObj, function (val) {
          if (!isEmpty(val.model)) {
            const findFile = filter(val.model, { id: fileObj.id })

            if (findFile && findFile.length >= 0) {
              return true
            }
          }
        })
        if (this.multiple && this.html5UploadSupport) {
          if (findFileIdx >= 0) {
            const fileIdx = findIndex(_self.uploadFileObj[findFileIdx].model, { id: fileObj.id })

            if (fileIdx >= 0) {
              _self.uploadFileObj[findFileIdx].model.splice(fileIdx, 1)
            }
          }
        } else {
          if (findFileIdx >= 0) {
            fileObj.active = false
            fileObj.destroy = true
            _self.$nextTick(function () {
              _self.uploadFileObj.splice(findFileIdx, 1)
              _self.emitFileList()
            })
          }
        }
      } else {
        const findFileIdx = findIndex(_self.rFileList, { key: fileObj.key })
        const removeItem = find(_self.rFileList, { key: fileObj.key })
        // 20241015 삭제처리를 위해 추가
        removeItem.success = false
        _self.deleteFileObj.push(removeItem)

        if (findFileIdx >= 0) {
          // _self.rFileList.splice(findFileIdx, 1)
          _self.$emit('rfile-input', { list: _self.rFileList, removed: removeItem })
        }
      }
    },
    getThumbImage(fileObj) {
      let blob = ''

      if (isEmpty(fileObj) || fileObj.type.substr(0, 6) !== 'image/') {
        // return defaultPreviewImg
        return ''
      }

      const URL = window.URL || window.webkitURL
      if (URL && URL.createObjectURL) {
        blob = URL.createObjectURL(fileObj.file)
      }
      // return isEmpty(blob) ? defaultPreviewImg : blob
      return isEmpty(blob) ? '' : blob
    },
    showFullSizeImg(src) {
      this.$modalOpen(UiFullSizeImgModal, { param: { imgSrc: src }, activeBtnClose: true, isFull: true })
    },
    getUploadSessionKey() {
      // const _self = this
      // _self.$http.post('/gw/api/member/user/redis/info').then(function (result) {
      //   if (result.data.code === '200' && !isEmpty(result.data.response.redisSessionId)) {
      //     _self.sessionKey = result.data.response.redisSessionId
      //     console.log(_self.sessionKey)
      //   }
      // }).catch(err => {
      //   console.log(err)
      // })
    },
    localInputFilter(newFile, oldFile, prevent) {
      const _self = this
      _self.$emit('input-filter', newFile, oldFile, prevent)

      if (isEmpty(newFile)) {
        prevent()
        return
      }
      if (!isEmpty(_self.extensions)) {
        const checkExtArray = _self.extensions
          .split(',')
          .map(value => value.trim())
          .filter(value => value)
        const checkRegex = new RegExp('\\.(' + checkExtArray.join('|').replace(/\./g, '\\.') + ')$', 'i')

        if (newFile.name.search(checkRegex) === -1) {
          prevent()
          _self.$emit('input-fail', 'extensions', newFile)
          return
        }
      }

      if (this.size > 0 && newFile.size >= 0 && newFile.size > this.size) {
        prevent()
        _self.$emit('input-fail', 'size', newFile)
        return
      }

      const findFileIdx = findIndex(this.uploadFileObj, function (val, idx) {
        if (idx !== _self.uploadFileObj.length - 1) {
          const findFile = filter(val.model, { name: newFile.name, size: newFile.size })

          if (findFile && findFile.length > 0) {
            return true
          }
        }
      })

      if (findFileIdx >= 0) {
        prevent()
        _self.$emit('input-fail', 'duplicate', newFile)
      }

      const rFileIdx = findIndex(this.rFileList, { name: newFile.name, size: newFile.size })

      if (rFileIdx >= 0) {
        prevent()
        _self.$emit('input-fail', 'duplicate', newFile)
      }

      // console.log('localInputFilter', newFile, oldFile, prevent)
    },
    emitFileList() {
      this.$emit('update:modelValue', this.showFileList)
    },
    async localInputFile(newFile) {
      // , oldFile
      const _self = this
      const ranVal = Common.randomValue(89999) + 10000

      if (!isEmpty(newFile)) {
        if (this.multiple && !this.html5UploadSupport) {
          _self.emitFileList()
        }

        if (_self.autoUpload) {
          const findObj = find(_self.showFileList, { name: newFile.name, size: newFile.size })

          if (!isEmpty(findObj)) {
            try {
              let result
              let uploadKey = ''

              if (isEmpty(_self.uploadKey)) {
                uploadKey = Common.randomValue().toString(36).substr(2).toUpperCase()
              } else {
                uploadKey = _self.uploadKey
              }

              if (_self.html5UploadSupport) {
                result = await _self.uploadHtml5(findObj, uploadKey)
              } else {
                result = await _self.uploadHtml4(findObj, uploadKey)
              }
              _self.checkUpload(findObj, true, result)
            } catch (err) {
              _self.checkUpload(findObj, false, err)
            }

            const successList = filter(_self.showFileList, { success: true })
            if (_self.showFileList.length === successList.length) {
              _self.$emit('upload_complete', _self.showFileList)
            } else {
              _self.$emit('upload_fail', _self.showFileList)
            }
          }
        }

        if (this.multiple && !this.html5UploadSupport) {
          _self.$nextTick(function () {
            _self.uploadFileObj.push({
              id: _self.id + '_' + ranVal,
              name: _self.name + '_' + ranVal,
              model: [],
              active: true,
              destroy: false
            })
          })
        }
      }
    },
    checkUpload(uploadFile, success, result) {
      const _self = this
      if (success) {
        uploadFile.success = success
        uploadFile.response = result

        if (this.multiple && !this.html5UploadSupport) {
          _self.emitFileList()
        }
      } else {
        uploadFile.success = false
        uploadFile.error = result

        if (this.multiple && !this.html5UploadSupport) {
          _self.emitFileList()
        }
      }
    },
    async uploadAll() {
      const _self = this
      const tokenApiUrl = '/GW/C1/jwtToken'
      await this.$http
        .post(tokenApiUrl, {}, { loadingOff: true })
        .then(async result => {
          const res = result.data
          const xJwtAuthToken = res.data
          this.tokenParams = {
            xJwtAuthToken: xJwtAuthToken,
            ..._self.uploadParams
          }
          if (res.result.code === 'SUCCESS') {
            let uploadKey = ''

            if (isEmpty(_self.uploadKey)) {
              uploadKey = Common.randomValue().toString(36).substr(2).toUpperCase()
            } else {
              uploadKey = _self.uploadKey
            }

            const uploadFiles = filter(_self.showFileList, { success: false })

            // 20241015 파일삭제 추가
            const deleteFiles = filter(_self.deleteFileObj, { success: false })
            if ((!uploadFiles || uploadFiles.length < 1) && (!deleteFiles || deleteFiles.length < 1)) {
              _self.$emit('upload_done')
              return
            }
            // const promiseList = uploadFiles.map(async function (newFile) {
            //   try {
            //     let result
            //     if (_self.html5UploadSupport) {
            //       result = await _self.uploadHtml5(newFile, uploadKey)
            //     } else {
            //       result = await _self.uploadHtml4(newFile, uploadKey)
            //     }
            //     _self.checkUpload(newFile, true, result)
            //   } catch (err) {
            //     _self.checkUpload(newFile, false, err)
            //   }
            // })
            let result
            if (_self.html5UploadSupport) {
              result = await _self.uploadHtml5(uploadFiles, uploadKey)
            } else {
              result = await _self.uploadHtml4(uploadFiles, uploadKey)
            }
            uploadFiles.map(function (file) {
              file.success = true
              file.response = result
            })
            // 20241016 파일삭제 추가
            deleteFiles.map(function (file) {
              file.success = true
              file.response = result
            })
            _self.checkUpload(uploadFiles, true, result)
            // await Promise.all(promiseList)
            const successList = filter(uploadFiles, { success: true })
            if (uploadFiles.length === successList.length) {
              if (deleteFiles.length > 0) {
                const returnList = [..._self.showFileList, ...deleteFiles]
                // _self.$emit('upload_complete', _self.showFileList)
                _self.$emit('upload_complete', returnList)
              } else {
                _self.$emit('upload_complete', _self.showFileList)
              }
              // _self.$emit('upload_complete', _self.showFileList)
            } else {
              _self.$emit('upload_fail', _self.showFileList)
            }
          } else {
            if (res.result.code === 'CE_99017') {
              this.$alert('알림', res.result.systemMessage).then(() => {
                this.$router.push('/')
              })
            } else {
              _self.$emit('upload_fail', _self.showFileList)
            }
          }
        })
        .catch(err => {
          console.log(err)
          _self.$emit('upload_fail', _self.showFileList)
        })
    },
    uploadHtml5(files, uploadKey) {
      const _self = this
      const formData = new FormData()
      let value
      for (const key in this.tokenParams) {
        value = this.tokenParams[key]
        if (value && typeof value === 'object' && typeof value.toString !== 'function') {
          if (value instanceof File) {
            formData.append(key, value, value.name)
          } else {
            formData.append(key, JSON.stringify(value))
          }
        } else if (value !== null && value !== undefined) {
          formData.append(key, value)
        }
      }
      if (!isEmpty(uploadKey)) {
        formData.append('uploadKey', uploadKey)
        formData.append('filePathId', uploadKey)
      }
      // 20241015 파일삭제 추가
      if (!isEmpty(uploadKey) && !isEmpty(this.deleteFileObj)) {
        formData.append('deleteFilePathId', uploadKey)
        const delSeqTxt = reduce(
          this.deleteFileObj,
          (crr, arr) => {
            if (!arr.success) {
              crr += !isEmpty(crr) ? '-' + arr.key : arr.key
              return crr
            }
          },
          ''
        )
        formData.append('deleteFilePathSeqno', delSeqTxt)
      }
      files.map(function (file) {
        formData.append('file', file.file, file.file.filename || file.name)
      })

      return new Promise((resolve, reject) => {
        _self.$http
          .post(_self.uploadUrl, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(function (result) {
            // console.log('[file] result', result)
            if (result.data.result.code !== 'SUCCESS') {
              reject(result.data)
            } else {
              resolve(result.data)
            }
          })
          .catch(function (err) {
            console.log('[file] err', err)
            const errObj = { code: 'uploadErr', message: '파일업로드에 실패하였습니다.' }
            reject(errObj)
          })
      })

      // let xhr = new XMLHttpRequest()
      // xhr.open('POST', file.postAction)
      // return this.uploadXhr(xhr, file, form)
    },
    uploadHtml4(_file, uploadKey) {
      const file = _file
      const uploadId = file.id + '-' + Date.now()

      const iframeElm = document.createElement('iframe')
      iframeElm.id = 'upload-iframe-' + uploadId
      iframeElm.name = 'upload-iframe-' + uploadId
      iframeElm.src = 'about:blank'
      iframeElm.setAttribute('style', 'width:1px;height:1px;top:-999em;position:absolute; margin-top:-999em;')
      iframeElm.setAttribute('crossorigin', 'anonymous')

      const formElm = document.createElement('form')

      formElm.action = this.uploadUrl

      formElm.name = 'upload-form-' + uploadId

      formElm.setAttribute('method', 'POST')
      formElm.setAttribute('target', iframeElm.id)
      formElm.setAttribute('enctype', 'multipart/form-data')
      formElm.setAttribute('encoding', 'multipart/form-data')

      const inputElm = document.createElement('input')
      inputElm.type = 'hidden'
      inputElm.name = 'redisSessionId'
      inputElm.value = this.sessionKey
      formElm.appendChild(inputElm)

      if (!isEmpty(uploadKey)) {
        const inputUploadKeyElm = document.createElement('input')
        inputUploadKeyElm.type = 'hidden'
        inputUploadKeyElm.name = 'uploadKey'
        inputUploadKeyElm.value = uploadKey
        formElm.appendChild(inputUploadKeyElm)
      }

      const fileParent = file.el.parentNode
      formElm.appendChild(file.el)

      document.body.appendChild(iframeElm)

      return new Promise((resolve, reject) => {
        const iframeCallBack = e => {
          let html = ''

          try {
            html = iframeElm.contentDocument.body.innerHTML
            if (html && /^<pre>/.test(html)) {
              html = html.replace(/<\/?pre>/gi, '')
            }
          } catch (e) {
            console.log(e, html)
          }

          const response = this.transformResponse(html, {})

          const data = Object.assign({}, response)
          switch (e.type) {
            case 'abort':
              data.error = 'abort'
              break
            case 'error':
              if (isEmpty(data.code)) {
                data.error = { code: '500', message: 'network' }
              }
              break
            default:
              if (isEmpty(data.code)) {
                data.error = { code: '500', message: 'network' }
              }
          }

          if (!isEmpty(data.error)) {
            reject(data.error)
            return
          }

          if (data.code !== '200') {
            reject(data)
            return
          }

          resolve(data)
        }

        iframeElm.onload = iframeCallBack
        iframeElm.onerror = iframeCallBack
        iframeElm.onabort = iframeCallBack

        formElm.submit()
      })
        .then(function (res) {
          fileParent && fileParent.appendChild(file.el)
          iframeElm.parentNode && iframeElm.parentNode.removeChild(iframeElm)
          return res
        })
        .catch(function (res) {
          fileParent && fileParent.appendChild(file.el)
          iframeElm.parentNode && iframeElm.parentNode.removeChild(iframeElm)
          throw res
        })
    },
    transformResponse(response, headers) {
      forEach(this.$http.defaults.transformResponse, transformFn => {
        response = transformFn(response, headers)
      })
      return response
    }
  }
}
</script>
